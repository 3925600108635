@import "src/styles/variables";

.PlaneCarousel {
  width: $page-width;
  margin: auto;

  .image-slide {
    margin: auto;
    display: flex;
    height: 300px;
    object-fit: contain;
  }

  .image-slide-disabled {
    margin: auto;
    display: flex;
    height: 300px;
    width: 100%;
    object-fit: contain;
    filter: grayscale(100%) opacity(50%);
  }

  .cover{
    object-fit: cover !important;
  }

  .carousel__slider--horizontal {
    height: 325px;
  }

  .carousel__next-button {
    right: 0;
    top: 50%;
    border: 0;
    position: absolute;
    background-color: inherit;

    &:disabled {
      //display: none;
      cursor: not-allowed;
    }
  }

  .carousel__back-button {
    top: 50%;
    border: 0;
    position: absolute;
    background-color: inherit;

    &:disabled {
      //display: none;
      cursor: not-allowed;
    }
  }

  .carousel__dot-group {
    text-align: center;
  }

  .carousel__dot {
    width: 100px;
    border: 0;
    height: 5px;
    background-color: #D5DCE7;

    &:first-child {
      border-top-left-radius: 2.5px;
      border-bottom-left-radius: 2.5px;
    }

    &:last-child {
      border-top-right-radius: 2.5px;
      border-bottom-right-radius: 2.5px;
    }
  }

  .carousel__dot--selected {
    background-color: $black;
    height: 5px;
  }

  .carousel__inner-slide {
    position: static;
  }
}

@media screen and ($mobile-media) {
  .PlaneCarousel {
    width: 100%;
    //height: 300px;
    .image-slide {
      max-height: 235px;
    }

    .carousel__slider--horizontal {
      height: 250px;
    }
  }

}