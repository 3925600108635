@import "src/styles/variables";

.AddPassengerExistingModal {
  .title {
    font-weight: 900;
    font-size: 30px;
    line-height: 140%;
    text-align: start;
  }

  .radio {
    .radio-tile {
      display: flex;
      padding-left: 15px;
      padding-top: 15px;
      padding-bottom: 15px;
      border-bottom: 1px solid $grey2;

      &:last-child {
        border-bottom: 0;
        padding-bottom: 10px;
      }

      label {
        margin-left: 10px;
        align-self: flex-end;
      }
    }
  }
}