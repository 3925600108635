@import 'variables';

@font-face {
  font-family: $font-bold;
  src: local($font-bold),
  url(../assets/font/FKGroteskNeue-1.1.1/woff-static/FKGroteskNeue-Bold.woff) format('woff');
}

@font-face {
  font-family: $font-medium;
  src: local($font-medium),
  url(../assets/font/FKGroteskNeue-1.1.1/woff-static/FKGroteskNeue-Medium.woff) format('woff');
}

@font-face {
  font-family: $font-regular;
  src: local($font-regular),
  url(../assets/font/FKGroteskNeue-1.1.1/woff-static/FKGroteskNeue-Regular.woff) format('woff');
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

* {
  font-family: $font-regular, serif;
}

.react-tiny-popover-container {
  .popup-info {
    max-width: 400px;
    background-color: $grey3;
    padding: 10px;
    border-radius: 10px;
    color: white;
  }
}

._loading_overlay_overlay {
  position: fixed !important;
}

.general-section {
  width: 100%;
  max-width: 968px;
  margin: 5% auto;
  padding:0 20px;
}

@media screen and ($tablet-media-min) and ($tablet-media) {
  #root {
    overflow-x: hidden;
  }
  .page-width {
    width: 100% !important;
    margin: auto;
    padding-left: 20px;
    padding-right: 20px;
  }
  .general-section {
    ul{
      padding:10px;
    }
    p{
      margin:0;
    }
  }
}

@media screen and ($mobile-media) {
  .general-section {
    ul{
      padding:0;
      list-style-type: none;
      li{
        list-style-position: inside;
      }   
    }
    p{
      margin:0 !important;
    }
    table{
      margin:20px 0 !important;
    }
  }
  .page-width {
    width: 100% !important;
    margin: auto;
    padding-left: 20px;
    padding-right: 20px;
  }
}

._loading_overlay_overlay {
  position: fixed !important;
}

.pointer-cursor {
  cursor: pointer;
}

.date-time-custom-header {
  select {
    margin-right: 10px;
  }
}

.text-align-center {
  text-align: center;
}

.color-white {
  color: $white;
}

.color-grey7 {
  color: $grey7;
}

.page-width {
  width: $page-width;
  margin: auto;
}

.modal-heading {
  width: 95%;
}
