@import 'src/styles/variables';

.root-container{
    height: 100%;
    background: $black;
    width:100vw;
}

.Marketplace{
    max-width: 1440px;
    margin: auto;
    .gen-temp-left,.gen-temp-right{
        .content{
            display: block !important;
            p{
                font-size: 18px;
            }
        }
        img{
            width: 42%;
        }
    }
    .Top-Section-mobile{
        display: none;
        margin-bottom: 45px;
        h1{
            font-size: 48px;
            font-style: normal;
            font-weight: 700;
            line-height: 48px;
            text-align: center;
            color: $grey1;
            margin-bottom:25px;
        }
        .main-img{
            border-radius: 12px;
            width: 90%;
            height: auto;
            margin: auto;
            display: block;
            margin-bottom: 35px;
        }
        .search-list{
            display: flex;
            padding: 10px 42px;
            p{
              margin: 0;
              color:$white;
              margin-bottom: 20px;
              max-width: 350px;
            }
            img{
              height:20px;
              margin-right:15px;
            }
          }
    }
    .Top-Section{
        display: flex;
        justify-content: space-between;
        max-height: 800px;
        margin: 0 auto;
        margin-bottom: 100px;
        .Left-Section{
            margin-top: 3%;
            padding-top:30px;
            padding-left: 10%;
            max-height: 800px;
            max-width: 50%;
            h1{
                padding-top: 20px;
                margin-bottom:60px;
                font-style: normal;
                font-weight: bold;
                font-size: 72px;
                // line-height: 31px;
                color: $white;
            }
            .search-list{
                display: flex;
                p{
                  margin: 0;
                  color:$white;
                  margin-bottom: 20px;
                  max-width: 350px;
                  font-size: 17px;
                }
                img{
                  height:20px;
                  margin-right:15px;
                }
              }
            }
            .right-img{
                max-height: 800px;
                max-width: 50%;
                height: auto;
                padding: 20px;
                border-radius: 40px;
                object-fit: cover;
            }
    }
    .gen-bottom {
        .gen-bottom-img {
          max-width: 1220px;
          width: 100%;
          margin: auto;
          img{
            margin: 0% 2.5%;
            width: 60%;
          }
        }
      
        .gen-bottom-content {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          // padding: 15% 5%;
          width: 404px;
          height: 256px;
          flex: none;
          order: 0;
          flex-grow: 0;
          margin: 5% auto;
      
          h3 {
            font-size: 48px;
            line-height: 110%;
            color: $grey1;
          }
      
          p {
            font-size: 18px;
            line-height: 20px;
            color: #9ca2a9;
          }
        }
      }
}

@media all and ($tablet-media-min) and ($tablet-media) {
    .Marketplace{
        .Top-Section{
            padding-top: 20px;
            max-height: 900px!important;
            margin-bottom: 140px!important;
          .Left-Section{
                padding: 20px 0;
                padding-left:2%;
                max-width: 45%;
                margin: 0;
                .search-list{
                    p{
                        max-width: 290px;
                    }
                }
                h1{
                    font-size:55px !important;
                    margin-bottom:20px !important;
                    word-break: break-word;
                }
            }
            .right-img{
                max-width:55%
            }
        }
        .gen-temp-left,.gen-temp-right{
            .content{
                display: block !important;
                p{
                    font-size: 16px;
                }
            }
        }
        .gen-bottom-content{
            p{
                font-size: 16px;
            }
        }
    }
}

@media all and ($tablet-media-min-hz) and ($tablet-media-hz) {
    .Marketplace{
        .Top-Section{
            padding-top: 20px;
            .Left-Section{
                padding: 20px 0;
                padding-left:5%;
                max-width: 45%;
                margin: 0;
                .search-list{
                    p{
                        max-width: 320px;
                    }
                }
                h1{
                    font-size:60px !important;
                    margin-bottom:25px !important;
                    word-break: break-word;
                }
            }
            .right-img{
                max-width: 55%;
            }
        }
    }
}

@media all and ($mobile-media) {
    .Marketplace{
        .gen-temp-left,.gen-temp-right{
            img{
                width: 100%;
            }
        }
        .gen-bottom-img img{
            width: 100% !important;
            margin: 0 !important;
        }
        .Top-Section-mobile{
            display: block !important;
        }
        .Top-Section{
            display: none !important
        }
        .gen-bottom-content{
            margin: 15% 5% !important;
        }
    }
}