@import 'src/styles/variables';

.LimoLinkModal{
    .title {
        font-weight: 900;
        font-size: 30px;
        line-height: 140%;
        text-align: start;

      }
    .subtitle{
        font-weight: 600;
        font-size: 25px;
        margin-top: 16px;
    }
    .subtitle:first-child{
        margin-top: 0;
    }
    .addStopTitle{
        margin-top: 16px !important;
    }
    .limo-info{
        color:$grey6;
        font-size: 14px;
    }
    .input-container {
        .add-stop, .remove-stop{
            cursor: pointer;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            p{
                margin: 0;
                padding: 19px 2px;
                color: $grey8;
            }
            svg{
                align-self: center;
                color: $grey3;
            }
        }
        .remove-stop{
            svg{
                transform: rotate(45deg);
            }
        }
        .full-width-container:first-child{
            border-top: 1px solid $grey4;   
        }
        .full-width-container {
            font-size: 17px;
            font-family: $font-regular;
            border: 0;
            padding-top: 16px;
            padding-bottom: 16px;
            border-bottom: 1px solid $grey4;
            width: 100%;
            color: $grey3;

            .react-datepicker-wrapper{
                width: 100%;
            }
            input{
                border: none;
                width: 100%;
                color: $grey3;
                &:focus {
                    outline: none;
                }
            }
            
            &:focus {
                outline: none;
            }
        }
        select{
            color: $grey8 !important;
        }
      }
}