@import "src/styles/variables";

.WiringInfoModal {
  .title {
    font-weight: normal;
    font-size: 30px;
    line-height: 140%;
    text-align: center;
  }

  .info {
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: $grey3;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
  }

  .account-info {
    background-color: $grey1;
    border-radius: 24px;
    padding: 20px;
    width: 335px;
    color: $black;
    margin: 20px auto 50px;

    > .tile {
      display: flex;
      width: 100%;
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }

      > p {
        margin-bottom: 0;

        &.label {
        }

        &.value {
          margin-left: auto;
        }
      }
    }
  }
}