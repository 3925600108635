@import 'src/styles/variables';

.AboutUs {
  .diverse-section {
    margin-top: 70px;
  }
}

.Profiles{
  max-width: 1300px;
  margin: 0 auto;
}

.Team-Heading{
  margin:4%;
  padding:20px;
  color: $grey1;
  font-size:50px;
  font-style: normal;
  // text-align: center;
  font-weight: bold;
}

.Team{
  margin:4%;
  padding:20px;
  color: $grey1;
  .Body{
    color:$grey2;
    p{
      margin:0 auto;
      font-size: clamp(14px,3vw,16px);
      line-height: 20px;
    }
    .Team-list{
      margin:10px auto;
    }
  }
  .Heading{
    display: flex;
    flex-direction: column;
    font-size:35px;
    font-style: normal;
    
    .Title{
      // width:100%;
      margin: 0 0 3px 0;
      display: flex;
      align-items: center;
      font-size: clamp(24px,5vw,30px);
      font-weight: 700;
    }
    .Email{
      // width:100%;
      font-size: clamp(20px,2vw,25px);
      display: flex;
      margin:0px 2% 2% 2%;
      align-items: center;
      color:$grey1;
      text-decoration: none;
      a{
        padding-left: 5px;
        display: flex;
        align-items: center;
        img{
          vertical-align: text-top;
        }
      }
    }
  }
}