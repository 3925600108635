@import "src/styles/variables";

.CreditCardDetails {
  margin-top: 40px;
  margin-bottom: 30px;

  > .title {
    font-weight: 600;
    font-size: 17px !important;
    line-height: 19px;
    maring-bottom: 10px;
  }

  .flex-container {
    display: flex;

    .input-container {
      width: 50%;

      .sub-label {
        margin-bottom: 0;
        font-size: 11px;
        text-align: inherit;
        margin-left: 20px;
      }

      &:first-child {
        margin-right: 10px;
      }
    }

  }

  input {
    &.cc-input {
      width: 100%;
      background-color: $grey4;
      border-radius: 12px;
      border: 0;
      padding: 10px 20px;
      color: $grey3;
      margin: 5px 0;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &:focus {
      outline: none;
    }
  }
}