.Accordions {
  .head {
    display: flex;
    align-self: center;
    padding-top: 5px;
    padding-bottom: 5px;

    .title {
      font-size: 35px !important;
      font-weight: 600;
    }

    .icon {
      align-self: center;
      margin-left: auto;
      cursor: pointer;
    }
  }

  .body {
    display: none;
    -webkit-animation: fadeIn 0.5s;
    animation: fadeIn 0.5s;

    &.active {
      display: block;
    }
  }
}