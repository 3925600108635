@import "src/styles/variables";

.ChangePasswordModal {
  .Modal {
    .title {
      margin-top: 0;
    }

    .action-container {
      display: flex;

      button {
        &:first-child {
          margin-right: 10px;
        }
      }
    }

    .input-containers {

    }

    .verification-containers {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
}