@import "src/styles/variables";

.ConciergeModal {
  .ConciergeModalContainer {
    display: block !important;
    .title {
      font-weight: 600;
      font-size: 30px !important;
      line-height: 38px;
      font-style: normal;
    }
    .sub-title {
      text-align: left;
      margin-top: 25px;

      font-style: normal;
      font-weight: 500;
      font-size: 17px;
      line-height: 19px;

      /* or 112% */
      letter-spacing: -0.01em;
      margin-bottom: 20px;
    }
  }
  .ConciergeListContainer {
    display: flex !important;
    border-top: 1px solid $grey2;
  }
  .itemLeft {
    padding-top: 25px;
    padding-bottom: 15px;
    width: 15%;
    font-weight: bold;
    font-size: 17px;
  }
  .itemRight {
    font-family: $font-regular;
    padding-top: 28px;
    padding-bottom: 28px;
    width: 80%;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #000;
  }
}
