@import 'src/styles/variables';

.Gallery{
 .modal{
     overflow-y: hidden;
 }
 .gallery-content{
     .PlaneDetails{
         display: flex;
         flex-direction: column;
         align-items: center;
         position: absolute;
         bottom: 10px;
         left: 0;
         right: 0;
         
         h4{
             font-weight: 600;
             margin-bottom: 20px;
         }
         h4,p{
             color: $white;
             max-width: 300px;
             text-align: center;
         }
         p{
             font-size: 13px
         }
     }
     .close{
         z-index: 1;
         opacity: 1;
     }
     .close:hover,
    .close:focus {
        color: inherit;
        text-decoration: none;
        cursor: pointer;
    }
     .PlaneCarousel{
         max-width: unset;
         width: 100%;
         margin-bottom: 0;
         background-color: rgba(0, 0, 0, 0.4);
         .carousel__inner-slide{
            display: flex;
            flex-direction: column;
            height: 100vh;
         }
         .image-slide{
             position: relative;
             bottom: 7%;
             height: 70vh;
             width: 90%;
         }
     }
     .carousel__slider--horizontal{
        height: 100vh;
        display: flex;
        flex-direction: column;
     }
 }   
}

@media screen and ($mobile-media) {
    .Gallery{
        .gallery-content{
            .PlaneCarousel{
                .image-slide{
                    max-height: unset;
                    bottom: unset;
                }
            }
            .PlaneDetails{
                bottom: 110px;
                top: unset;
            }
        }
    }
}

@media screen and ($tablet-media-min) and ($tablet-media){
    .Gallery{
        .gallery-content{
            .PlaneDetails{
                bottom: 50px;
            }
            .image-slide{
                bottom: unset !important;
            }
        }
    }
}