@import '/src/styles/variables.scss';

.main-section {
  height: 100%;
  background-color: $white;
  margin-bottom: 100px;
}

.sub-section {
  max-width: 1440px;
  width: 100%;
  margin: auto;
}

.rating-card {
  display: flex;
  justify-content: space-evenly;
  margin: 5% auto;
  width:100%;

  img{
    max-height: 300px;
    width: auto;
    height: auto;
  }

  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 110%;
    margin-bottom: 20px;
  }

  div {
    // max-width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 2% 4%;
  }
}

.rating-wvyern {
  flex-direction: row-reverse;
  div{
    h2,p{
      text-align: end;
    }
  }
}

@media screen and ($mobile-media) {
  .rating-card {
    flex-direction: column;
    margin: unset;

    img {
      margin: 10% 0;
      width: 100%;
    }

    div {
      // margin: 5%;
      h2,p{
        text-align: start;
      }
    }
  }

  .safety-rating-content {
    height: 100% !important;
    margin-bottom: 15% !important;
    p{
      width:100%;
    }
  }

  .rating-card > div {
    max-width: 375px;
  }
}

@media screen and ($tablet-media-min) and ($tablet-media) {
  .rating-card {
    flex-direction: column;
    margin: unset;

    img {
      margin: 10% auto;
      width: auto;
    }    

    // div {
    //   margin: 5%;
    // }
  }
  .rating-wvyern {
    div{
      h2,p{
        text-align: start;
      }
    }
  }
}
