@import "src/styles/variables";

.carbon-offset {
  background-color: $grey1;
  padding: 20px;
  border-radius: 25px;
  margin-bottom: 30px;
}

.carbon-head {
  padding-bottom: 20px;
  font-weight: 600;
  font-size: 22px;
}

.body-carbon-offset {
  background-color: #aafe3f;
  border-radius: 24px;
  display: flex;
}

.carbon-logo {
  padding-left: 10px;
  align-self: center;
  width: 50px;
  padding-right: 10px;
}

.carbon-link {
  font-size: 16px;
  align-self: center;
  margin-left: auto;
  cursor: pointer;
  color: $black;
  padding-right: 10px;
  padding-left: 10px;
}

.popup-carbon-info {
  background-color: $secondary-skyblue;
  max-width: 400px;
  padding: 10px;
  border-radius: 10px;
  color: white;
}

.carbon-details {
  font-size: 14px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 10px;
}
