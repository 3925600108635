@import "src/styles/variables";

.WalletPayBreakDown {
  background-color: $grey1;
  border-radius: 24px;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 20px;

  .extra-info {
    padding-top: 20px;

    .tile {
      border-bottom: none;
    }

    .info-text {
      margin-bottom: 0;
      color: $grey7;
    }
  }

  .tile {
    display: flex;
    padding-bottom: 20px;
    padding-top: 20px;
    border-bottom: 1px solid $grey2;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
      border-bottom: none;
    }

    .key {
      font-weight: 600;
      font-size: 17px;
      line-height: 19px;
      margin-bottom: 0;

      &.blue {
        color: $blue;
      }
    }

    .value {
      margin-left: auto;
      font-weight: 600;
      font-size: 17px;
      line-height: 19px;
      margin-bottom: 0;

      &.blue {
        color: $blue;
      }
    }
  }
}