@import "src/styles/variables";

.TripCard {
  background-color: $grey1;
  padding: 25px;
  border-radius: 20px;
  margin-bottom: 25px;
  //width: 650px;

  .title-container {
    display: flex;
    font-size: 23px;
    font-weight: 500;
    line-height: 25px;
    padding-bottom: 20px;
    border-bottom: 1px solid $grey2;

    .date-container {
      color: $black;

      p {
        margin-bottom: 0
      }
    }

    .step-container {
      color: $grey3;
      align-self: center;
      margin-left: auto;

      p {
        margin-bottom: 0
      }
    }

  }
  .notes-style {
    min-Height: 100;
    width: 100%;
  }
  .note-text {
    padding-top: 10px;

  }
  .airport-container {
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid $grey2;
    display: flex;

    .icon-container {
      padding-right: 10px;

      .v-line {
        height: 60px;
        border-left: 2.5px dotted $black;
        margin-left: 7px;
        margin-bottom: -10px;
        margin-top: -4px;
      }
    }

    .places-container {
      width: 100%;
      .restriction-container{
        display: flex;
        justify-content: space-between;
        .info-button{
          cursor: pointer;
        }
        p{
          color: $blue1;
          position: relative;
          top: 1px;
          font-size: 14px;
          vertical-align: middle;
          margin-bottom: 0;
          padding-left: 10px;
          margin-right: 10px;
        }
        .restriction{
          display: flex;
          cursor: pointer;
          svg{
            align-self: center;
          }
        }
      }

      .airport-detail {
        display: flex;
        width: 100%;
        margin-bottom: 0;

        .a-name {
          margin-bottom: 0;
          font-size: 20px;
          font-weight: 500;
        }

        .dot {
          margin-top: 10px;
          margin-left: 10px;
        }

        .city-name {
          margin-top: 2px;
          margin-left: 10px;
          margin-bottom: 0;
          margin-right:3px;
        }

        .time {
          margin-top: 2px;
          align-self: center;
          margin-left: auto;
          float: right;
          margin-bottom: 0;
          text-align: end;
          min-width:72px;
        }
      }

      .meta-container {
        display: flex;
        margin-top: 10px;
        margin-bottom: 10px;

        .h-line {
          width: 78%;
          flex-grow: 1;
          border-top: 1px solid $grey2;
          margin-top: 8px;
        }
      }
    }
  }

  .actions-container {
    padding-top: 10px;

    button {
      &:last-child {
        float: right;
      }
    }
  }
}

@media screen and ($mobile-media) {
  .TripCard {
    padding:15px;
  }
  
}