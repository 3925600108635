@import '/src/styles/variables.scss';

.aircraft-overview {
  max-width: 1380px;
  width: 100%;
  max-height: 750px;
  margin: auto;
  background-color: aliceblue;

  .aircraft-img {
    display: flex;
    justify-content: center;
    width: 100% !important;
    height: auto;
    // padding-top: 55px;
    margin: auto;
    img{
      width:90%;
      object-fit: contain;
      margin:0 auto;
    }
  }
  
}

@media screen and ($mobile-media) {
  .aircraft-overview {
    height: unset;
    padding: 5% 0;
    .aircraft-img {
      img{
        object-fit: contain;
      }
    }
  }

  .aircraft-min-info {
    display: block;

    p {
      width: unset;
      text-align: center;
      font-size: 16px;
    }

    div {
      text-align: center;
    }
  }
}
