@import "src/styles/variables";

.FAQPage {
  padding:0 1%;
  h2 {
    margin-top: 80px;
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 20px;
  }

  h1 {
    font-size: 31px;
    font-weight: 900;
    text-align: center;
  }
}

@media screen and ($mobile-media) {
  .FAQPage {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and ($tablet-media-min) and ($tablet-media) {
  .FAQPage {
    padding-left: 20px;
    padding-right: 20px;
  }
}