@import "src/styles/variables";

.TextLabelTile {
  padding: 17px 20px;
  border-radius: 24px;
  background-color: $grey1;

  > .title {
    color: $grey3;
    margin-bottom: 0;
  }

  > .content {
    color: $black;
    margin-bottom: 0;
    font-size: 21px;
    font-weight: 600;
  }
}