@import "src/styles/variables";

.AddPassengerModal {

  .checkbox{
    border-radius: 100px;
  }
  .container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    border: 0;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    border-radius:100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
  }
  
  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: #ccc;
  }
  
  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: #000;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 8px;
    top: 5px;
    width: 4px;
    height: 9px;
    border: solid white;
    border-width: 0 1px 1px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .title {
    font-weight: 900;
    font-size: 30px;
    line-height: 140%;
    text-align: start;
  }

  .tab-container {
    display: flex;
    justify-content: center;

    button {
      margin-right: 10px;
      padding-left: 25px;
      padding-right: 25px;
    }
  }


  .input-container {
    .full-width-container {
      border: 0;
      padding-top: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid $grey1;
      width: 100%;
      color: $grey3;

      &:focus {
        outline: none;
      }
    }
  }

  .passport-input{
    .react-datepicker-wrapper {
      input {
        padding-top:0;
        padding-bottom:0;
      }
    }
    padding-bottom: 10px;
    padding-top: 20px;
    .pass-full-width-container {
      border: 0;
      width: 100%;
      color: $grey3;
      
      &:focus {
        outline: none;
      }
    }
    .subtext{
      color:$grey3;
      padding: 0 2px;
      font-size:10px;
    }
    border-bottom: 1px solid $grey1;
  }

  #sectionForm{
    height: calc(100% - 24px);
    position: relative;
    bottom: 20px;
  }

  .leg-container{
    h6{
      margin-bottom: 15px;
    }
    .Dummy{
      padding: 0;
      position: relative;
      left:10px;
      top:20px;
      margin: 0;
      border:0;
      height:1px;
      width:1px;
    }
    color:$grey3;
    padding-top: 25px;
    border-bottom: 1px solid $grey1;
  }

  .dob-container{
    h6{
      color:$grey3;
    }
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid $grey1;
    .react-datepicker-wrapper {
      input {
        border: 0;
        width: 100%;
        color: $grey3;
  
        &:focus {
          outline: none;
        }
      }
    }
  }
}