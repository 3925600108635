@import 'src/styles/variables';

.EditAirport {
  min-height: 700px;
  display: flex;

  .info-container {
    width: 25%;
    position: relative;

    > .leg-container {
      > h1 {
        font-weight: 600;
        font-size: 32px;
        line-height: 41px;
        padding: 20px;
      }

      .airport-container {
        display: flex;
        padding: 20px;
        cursor: pointer;

        &:hover {
          outline: 1px solid $grey3;
        }

        p {
          margin-bottom: 0;
        }

        &.active {
          background-color: $grey1;
        }

        .city {
        }

        .code {
        }

        .dot {
          margin-top: 5px;
          margin-left: 10px;
          margin-right: 10px;
        }

        .depart {
          margin-top: 3px;
          margin-right: 15px;
        }
      }

      > .suggested-airport-container {
        height: 350px;
        overflow: auto;

        .suggested-airport {
          padding: 20px;
          cursor: pointer;

          &:hover {
            outline: 1px solid $grey3;
          }

          &.active {
            background-color: $grey1;
          }

          .title {
            font-weight: 600;
            font-size: 18px;
            line-height: 23px;
            margin-bottom: 5px;
          }

          .city {
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            margin-bottom: 0;
          }
        }
      }
    }

    > .btn-container {
      position: absolute;
      bottom: 0;
      display: flex;
      width: 100%;
      justify-content: center;

      > button {
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }

  .map-container {
    width: 75%;

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.AirportMarker {
  &.active {
    > .info-container {
      > .airport-code {
        background-color: $yellow;
        color: $black;
      }
    }
  }

  > .info-container {
    display: flex;
    width: 270px;

    > .airport-code {
      background-color: $black;
      color: $white;
      align-self: center;
      width: 80px;
      text-align: center;
      padding: 30px 15px;
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
    }

    > .airport-name {
      padding: 20px;
      background-color: $white;
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
    }
  }

  .marker {
    padding: 2px;
    width: 20px;
    margin-left: -20px;
    height: 20px;
    background-color: $black;
  }
}

@media screen and ($tablet-media-min) and ($tablet-media) {
  .EditAirport {
    flex-wrap: wrap;
    height: auto;

    .map-container {
      width: 95%;
      margin: 2.5% auto;
      height: 450px;
    }

    .info-container {
      width: 95%;
      margin: auto;
    }
  }
}
