@import "src/styles/variables";

.InformationModal{
    ul{
        padding-left: 15px;
    }
    .title {
        font-weight: 900;
        font-size: 30px;
        line-height: 140%;
        text-align: start;
      }
    .info{
        font-size: 15px;
        margin-bottom: 10px;
    }
}