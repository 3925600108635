@import "src/styles/variables";

.LegsModal {
  .checkbox {
    border-radius: 100px;
  }

  checkbox{
    border-radius: 100px;
  }
  .all-legs {
    border-top: 1px solid $grey1;
    color: #000;
    font-weight: 600;
  }

  .container {
    display: block;
    position: relative;
    border-bottom: 1px solid $grey1;
    margin: 6px 0;
    padding: 12px;
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    border: 0;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    border-radius: 100%;
    position: absolute;
    right: 20px;
    height: 20px;
    width: 20px;
    background-color: #eee;
  }

  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the checkbox is checked, add a black background */
  .container input:checked ~ .checkmark {
    background-color: #000;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 8px;
    top: 5px;
    width: 4px;
    height: 9px;
    border: solid white;
    border-width: 0 1px 1px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .title {
    // padding-left: 10px;
    font-weight: 900;
    font-size: 30px;
    line-height: 140%;
    text-align: start;
  }

  .tab-container {
    display: flex;
    justify-content: center;

    button {
      margin-right: 10px;
      padding-left: 25px;
      padding-right: 25px;
    }
  }

  .input-container {
    .full-width-container {
      border: 0;
      padding-top: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid $grey1;
      width: 100%;
      color: $grey3;

      &:focus {
        outline: none;
      }
    }
  }

  .passport-input {
    .react-datepicker-wrapper {
      input {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
    padding-bottom: 10px;
    padding-top: 20px;
    .pass-full-width-container {
      border: 0;
      width: 100%;
      color: $grey3;

      &:focus {
        outline: none;
      }
    }
    .subtext {
      color: $grey3;
      padding: 0 2px;
      font-size: 10px;
    }
    border-bottom: 1px solid $grey1;
  }

  #sectionForm {
    height: calc(100% - 24px);
    position: relative;
  }

  .lead-pax-container{
      .title{
          margin: 20px 0 10px 0;
          font-size: 22px;
      }
      p{
        padding-left: 0px;
      }
      .lead-subtext{
          color:$grey3;
          font-size: 15px;
          line-height: 16px;
          margin-top:5px;
          text-align: start;
      }
      .lead-pax {
        border-top: 1px solid $grey1;
        color: #000;
        padding-left: 2px;
      }
      padding-left:10px;
  }

  .leg-container {
    position: relative;
    h6 {
      margin-bottom: 15px;
    }
    .Dummy {
      padding: 0;
      position: absolute;
      right: 30px;
      top: 25px;
      margin: 0;
      border: 0;
      height: 1px;
      width: 1px;
    }
    color: $grey3;
  }

  .dob-container {
    h6 {
      color: $grey3;
    }
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid $grey1;
    .react-datepicker-wrapper {
      input {
        border: 0;
        width: 100%;
        color: $grey3;

        &:focus {
          outline: none;
        }
      }
    }
  }
}
