@import 'src/styles/variables';

.signup-root {
  height: 100%;
  background-color: $black;
  padding: 5%;

  .title {
    font-size: 350%;
    line-height: 80px;
    text-align: center;
    color: $white;
    width: 100%;
  }
}

.signup-form {
  width: 25%;
  margin: auto;
  padding: 2.5% 0;
}

.input-grp {
  font-size: 16px;
  background-color: $black;
  display: block;
  margin: 10px 0;
  width: 100%;
  border: none;
  padding: 10px 0;
  border-bottom: 1px solid #2c3034;
  color: $white;
  outline: none;
}

.btn-grp {
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
  border-radius: 30px;
  font-size: 20px;
  line-height: 22px;
  padding: 18px 72px;
  display: block;
  width: 100%;
  outline: none;
}

.primary-btn {
  margin-top: 60px;
  background: $yellow;
}

.cust-no {
  display: flex;

  .tel-number {
    width: 30%;
    padding-left: 10px;
    padding-right: 10px;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

@media screen and ($mobile-media) {
  .signup-form {
    width: 85%;
  }
  .signup-root {
    .title{
      font-size: 300%;
    }
  }
}

@media screen and ($tablet-media-min) and ($tablet-media) {
  .signup-form {
    width: 40%;
  }
}
