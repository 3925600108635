@import '/src/styles/variables.scss';

.aircraft-title {
  font-style: normal;
  font-weight: bold;
  font-size: 175px;
  line-height: 170px;
  text-align: center;
  letter-spacing: -0.04em;
  margin-top: 30px;
}

.disclaimer{
  color:$grey3;
  margin: 0 0 50px;
  padding: 0 clamp(32px,10%,60px);
  a{
    color: $grey5;
    text-decoration: none;
  }
}

.aircraft-card-content-li{
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 40%;
  h6{
    font-size: 1rem;
    margin: 10px 23px;
    width: 40%;
    white-space: nowrap;
  }
}

.aircraft-tabs {
  max-width: 1080px;
  width: 100%;
  margin: 20px auto;
  height: 80px;
  display: flex;
  justify-content: space-around;

  button {
    width: 100%;
    // padding: 16px;
    border-radius: 30px;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 15px;
    border: unset;
    height: 60px;
  }

  .btn-active {
    color: $white;
    background: $blue;
    box-shadow: 0px 5.53844px 11.0769px rgba(0, 0, 0, 0.2);
  }

  .btn-inactive {
    color: $black;
    background: $white;
  }
}

.vector-lines {
  width: 39%;
  height: 34px;
}

.aircraft-data {
  display: flex;
  justify-content: space-around;
  // margin: 50px;

  p {
    position: relative;
    top: -15px;
    font-style: normal;

    font-weight: 600;
    font-size: 1.8vw;
    line-height: 26px;
  }
}

@media screen and (min-width: 1236px) {
  .aircraft-data { 
    p{
     font-size: 20px;
  }
}}

.aircraft-min-info {
  display: flex;
  justify-content: space-between;
  margin: 5%;

  p {
    font-size: 16px;
    line-height: 20px;
    color: #2c3034;
    opacity: 0.8;
    width: 280px;
  }

  span {
    margin: 0 15px;
  }
}

.aircraft-type-info {
  width: 100%;
  max-width: 490px;
  height: 361px;
  margin: 5% auto;
  text-align: center;

  img {
    margin: 5%;
  }

  .info {
    white-space: pre-wrap;
  }
}

.aircrafts-cards-section {
  display: flex;
  flex-wrap: wrap;
  margin: 50px 0;

  .aircraft-card {
    padding: 20px;
    width: 50%;
  }

  .aircraft-card-header {
    width: 100%;
    height: 300px;
    background: rgba(241, 244, 249, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .aircraft-card-content {
    margin: 40px;

    h3 {
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      line-height: 46px;
    }

    ul {
      list-style: none;
      padding: 0;
      display: flex;
      flex-direction: column;
      height: 150px;
      flex-wrap: wrap;
      align-content: baseline;

      li {
        width: 200px;
      }
    }

    p {
      cursor: pointer;

      span {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
      }

      img {
        margin-left: 10px;
        transform: rotate(-90deg);
      }
    }
  }
}

.aircraft-data-mobile {
  display: none;
}

@media screen and ($mobile-media) {
  .aircraft-title {
    font-size: 72px;
    line-height: 92px;
  }

  .aircraft-card-content-li{
    grid-template-columns: 1fr;
  }

  .aircraft-tabs {
    width:100vw;
    overflow-x: scroll;
    display: flex;

    button {
      // width:18%;
      font-size: 12px;
    }
  }

  .aircraft-img {
    width: 100% !important;
    height: 275px !important;

    img {
      height: 181px;
    }
  }

  .aircraft-card-header {
    width: 100% !important;
    height: 250px !important;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      margin: 50px 25px !important;
    }
  }

  .aircraft-data-mobile {
    display: flex;
    justify-content: space-around;
    margin: 10px;

    p {
      position: relative;
      top: -15px;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 26px;
    }
  }

  .aircraft-data {
    display: none;
  }

  .vector-lines-mobile {
    width: 75px;
    height: 8px;
  }

  .aircraft-card-content {
    margin: 15px 10px !important;

    h2 {
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 31px;
    }

    ul {
      li {
        width: 150px;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
      }
    }
  }

  .aircraft-card {
    width: 100% !important;
  }

  .aircraft-type-info {
    height: unset;

    p {
      margin: 20px !important;
    }
  }

  .aircraft-overview {
    height: unset;
    padding: 5% 0;
  }

  .aircraft-min-info {
    display: block;

    p {
      width: unset;
      text-align: center;
      font-size: 16px;
    }

    div {
      text-align: center;
    }
  }
}

@media screen and ($tablet-media-min) and ($tablet-media) {
  .aircraft-tabs button {
    font-size: 15px;
    width: 150px;
  }

  .aircraft-title {
    font-size: 145px;
    line-height: 175px;
  }

  .aircraft-overview .aircraft-img {
    width: 768px !important;
  }

  .vector-lines {
    width: 37%;
    height: 20px;
  }
  // .aircraft-data { 
  //   p{
  //    font-size: 2vw;
  // }
// }

  .aircrafts-cards-section {
    .aircraft-card {
      margin: 20px;
      width: 95%;
    }

    .aircraft-card-header {
      width: 100%;
    }

    .aircraft-card-header img {
      margin: 144px 180px;
    }
  }
}
