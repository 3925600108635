@import "src/styles/variables";

.ApplicationTab {
  background-color: black;
  height: 70px;

  .tab-container {
    position: relative;
    max-width: $page-width;
    width: 90%;
    margin: auto;
    display: flex;
    justify-content: center;
    height: 100%;

    
    .tab {
      position: relative;
      z-index: 0;
      text-align: center;
      cursor: pointer;
      letter-spacing: 0.5px;
      transition: all 0.2s ease-in;
      color: white;
      user-select: none;
      display: flex;
      align-items: center;
      flex: 1 1 0;
      justify-content: center;
      font-size: 18px;
      border-top-left-radius: 25px;
      border-top-right-radius: 25px;
      max-width: 300px;
    }


    div:before,
    div:after {
      position: absolute;
      bottom: -1px;
      width: 20px;
      height: 20px;
      content: " ";
    }

    div:before {
      left: -20px;
      border-bottom-right-radius: 20px;
      border-width: 0 1px 1px 0;
    }
    div:after {
      right: -20px;
      border-bottom-left-radius: 20px;
      border-width: 0 0 1px 1px;
    }

    .selected-tab:before {
      transition: 0.2s ease-in;
      box-shadow: 7px 3.5px 0 #FFF;
    }
    .selected-tab:after {
      transition: 0.2s ease-in;
      box-shadow: -7px 3.5px 0 #FFF;
    }
    
    .selected-tab {
      // z-index: 3;
      background-color: white;
      color: black;
    }

    .tab-content {
      height: 400px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}


@media screen and ($mobile-media) {
  .ApplicationTab {
    .tab-container {
      width: 100%;

      .tab {
        font-size: 12px;
        padding: 5px;
      }
    }
  }
}