$page-width: 968px;
$page-short-width: 700px;
$black: #000;
$white: #fff;
$yellow: #ebff0c;
$blue: #3881ef;
$blue1:#4782E7;
$red: #ef3838;
$dark-blue: #1d6add;
$grey1: #f1f4f9;
$grey2: #cfcdcd;
$grey3: #848b94;
$grey4: #d5dce7;
$grey5: #2c3034;
$grey6: #535a61;
$grey7: #9ca2a9;
$grey8: #666d74;

$font-regular: 'FKGroteskNeue-Regular';
$font-medium: 'FKGroteskNeue-Medium';
$font-bold: 'FKGroteskNeue-Bold';
$mobile-media: 'max-width: 451px';
$tablet-media-min: 'min-width: 451px';
$tablet-media: 'max-width: 800px';
$tablet-media-min-hz: 'min-width: 800px';
$tablet-media-hz: 'max-width: 1200px';

// Guide Color Code ----------------------------------

$primary-black: #000000;
$primary-white: #FFFFFF;
$primary-gray: #F1F4F9;
$secondary-yellow: #EBFF0C;
$secondary-skyblue: #3881EF;
$secondary-darkblue: #1D6ADD;
$light-gray1: #F1F4F9;
$light-gray2: #D5DCE7;
$light-gray3: #848B94;
$dark-gray1: #2C3034;
$dark-gray2: #535A61;
$dark-gray3: #9CA2A9;
$error-red: #EF3838;
$success-green : #AAFE3F;


