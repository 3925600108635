@import 'src/styles/variables';

.create-root {
  height: 100%;
  background-color: $black;
  padding: 5%;

  .title {
    font-size: 400%;
    line-height: 100%;
    text-align: center;
    color: $white;
    width: 100%;
  }
}

.signup-btns {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 25%;
  margin: auto;
  padding: 5% 0;

  .link {
    text-align: center;
    padding: 10px 0;
  }
}

.links {
  color: $white;

  &:hover {
    text-decoration: none;
    color: $white;
  }
}

.btn-grp {
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
  border-radius: 30px;
  font-size: 20px;
  line-height: 22px;
  padding: 18px 72px;
  margin: 10px;
  display: block;
}

.primary-btn {
  background: $yellow;
}

@media screen and ($mobile-media) {
  .signup-btns {
    width: 95%;
  }
  .create-root {
    .title {
      font-size: 300%;
    }
  }
}

@media screen and ($tablet-media-min) and ($tablet-media) {
  .signup-btns {
    width: 40%;
  }
}
