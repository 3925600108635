@import "src/styles/variables";

.UpdatePersonalInfoModal{
    .title {
        font-weight: 900;
        font-size: 30px;
        line-height: 140%;
        text-align: start;
      }

      .Button{
          font-weight: 600;
      }
    
    .phone-input::placeholder {
        color: #ff0000;
        opacity: 1;
    }
    .phone-input{
        border: 0;
        width: 100%;
        font-weight: 600;
        &:focus {
          outline: none;
        }
    }
  
    .full-width-container {
        border: 0;
        font-size:18px;
        width: 100%;
  
        &:focus {
          outline: none;
        }
      }
  
    .key-value {
      padding-top: 15px;
      padding-bottom: 15px;
      padding-left: 10px;
      border-bottom: 1px solid $grey2;
  
      .key {
        margin-bottom: 0;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        color: $grey3;
        padding-bottom: 5px;
      }
  
      .value {
        margin-bottom: 0;
        font-weight: 600;
        font-size: 18px;
        line-height: 23px;
      }
    }
    .guideline{
        padding: 0 10px;
        padding-top: 10px;
        font-weight: 600;
        color: $red;
        font-size: 15px;
    }
}