@import 'src/styles/variables';

.ReviewBooking {
  .reserve-heading{
    font-weight: 600;
  }
  > .content {
    max-width: $page-short-width;
    width:95%;
    margin: auto;
    padding-top: 40px;
    padding-bottom: 100px;

    > h2 {
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 41px;
      padding-bottom: 10px;
      padding-top: 60px;

      &:first-child {
        padding-top: 20px;
      }
    }

    .attention{
      margin-top:20px;
      margin-bottom:0px;
      padding-bottom: 0px;

    }
  }

  .complete-payment-modal {
    > .term-agreement-title{
      p{
        font-size:14px;
        color:$grey5;
        font-weight: 600;
        margin-bottom: 10px;
      }
    }
    > .term-agreement{
      display: flex;
      // flex-direction: row;
      .terms{
        font-size:14px;
        color:$grey6;
        a{
          text-decoration: underline;
          color:$grey5;
          font-weight: 900;
        }
      }
    }
    > h1 {
      font-size: 25px;
      line-height: 140%;
    }

    > .light-text {
      font-size: 13px;
      line-height: 14px;
      color: $grey6;
    }
  }

  ._loading_overlay_overlay {
    position: fixed;
  }
}

.ReviewBookingHeader {
  background-color: $black;
  color: $white;
  padding-top: 33px;
  padding-bottom: 22px;

  > h1 {
    max-width: $page-width;
    width:95%;
    margin: auto;
    font-size: 60px;
    font-weight: 600;
    // line-height: 10px;
    padding-bottom: 10px;
  }
}

@media screen and ($tablet-media-min) and ($tablet-media) {
  .ReviewBookingHeader > h1 {
    width: 95%;
  }
  .peak-msg {
         width: 75%!important;  
         margin-right: 15%!important;
  }
  
}

@media screen and ($mobile-media) {
  .ReviewBookingHeader {
    > h1 {
      width: 100%;
      padding: 20px;
    }
  }
  .ReviewBooking {
    > .content {
      padding: 20px;
      width: 100%;
    }
  }
  .TextLabelTile{
    > .content{
      p{
        font-size: 20px;
      }
    }
  }
  .peak-msg {
       width: 100%!important;  
       margin-right: 3%!important;  
  }
  .peak-div-style {
    .check-box {
      margin-top: 5px!important;  
    }
  }
}

.peak-trip {
  margin-bottom: 20px;
  margin-top: -20px;
}

.peak-div-style {
  margin-top: 15px;
  // margin-bottom: 25px;
  margin-bottom: -2px;
  border-radius: 24px;
  background-color: $error-red;
  padding: 20px 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  .info-text {
    color: $white;
    font-size: 15px;
  }
  .peak-headline {
    // text-align: center;
    color: #FFFFFF;
    margin-bottom: 5px;
    font-size: 21px;
    font-weight: 600;
    margin-left:48px;
  }
  .header {
    // margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    .icon {
      align-self: center;
      width: 29px;
      height: 26.3px;
      color: $primary-gray;
      margin-top: -25px;
    }
    .peak-msg {
      // color: $white;
      // margin-bottom: -10px;
      // margin-left: 15px;
      margin-left: 18px;
      color: #FFFFFF;
      margin-bottom: 5px;
      font-size: 14px!important;
      margin-right: 23%;
      margin-top: -1px;
      width: 63%;
     }
  }
  .check-box {
    margin-right: -5px;
    margin-top: -15px;
  }

}

.peak-validation {
  display: inline-flex;
  margin-top: 15px;
  margin-bottom: 5px;
}

.peak-trip-msg {
  margin: -1px 7% 5px 18px!important;
  width: 100%!important;
}

.peak-validation-icon {
  width: 15px;
  height: 19px;
  font-family: FK Grotesk Neue;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: #EF3838;
}

.peak-validation-msg {
  font-family: FK Grotesk Neue;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #EF3838;
}

.peak-div-validation {
  border: 2px solid #EF3838;
  box-sizing: border-box;
  border-radius: 24px;
}

.peak-div-validation-style {
    margin-top: 7px;
    margin-bottom: 7px;
    margin-left: 6px;
    margin-right: 6px;
}

.term-agreement{
  display: flex;
 
}
.terms a{
  color: #848b94;
  text-decoration: underline;
}
.terms p{
  margin-top:10px;
}

.reserve-title{
//  padding-top: 250px;
margin-top:80px;
}


.terms a:hover {
  color: #848b94;
}