@import 'src/styles/variables';

.InputBox {
  > .input-container {
    display: flex;

    svg {
      align-self: center;
      margin-left: 10px;
      cursor: pointer;
    }

    > input {
      font-size: 16px;
      display: block;
      margin: 10px 0;
      width: 100%;
      border: none;
      padding: 10px 0;


      &:focus {
        outline: none;
      }
    }
  }

  &.black-input {
    > .input-container {
      > input {
        background-color: $black;
        color: $white;
        border-bottom: 1px solid #2c3034;
      }
      > .eye-container{
        color:white;
        margin:10px 0;
        padding:10px 0;
        border-bottom: 1px solid #2c3034;
        svg{
          position: relative;
          right:10px;
          bottom:1px;
          margin-left:0;
        }
        
      }
    }
  }

  &.light-input {
    > .input-container {
      > input {
        background-color: $white;
        color: $black;
        border-bottom: 1px solid $grey4;
        //padding-left: 10px;
      }
      > .eye-container{
        margin:10px 0;
        padding:10px 0;
        border-bottom: 1px solid $grey4;
        svg{
          position: relative;
          right:10px;
          bottom:1px;
          margin-left:0;
        }
        
      }
    }
  }

  label {
    margin-bottom: -10px;
    margin-top: 10px;
  }
}
