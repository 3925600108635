@import "src/styles/variables";

.CompletePayment {
  .PaymentOptions {
    margin-bottom: 40px;
  }
}

.PaymentOptions {
  background-color: $grey1;
  padding: 20px;
  border-radius: 24px;

  > .header {
    padding-bottom: 20px;
    border-bottom: 1px solid $grey2;

    > .title {
      color: $blue;
      margin-bottom: 5px;
      font-size: 21px;
      font-weight: 600;
    }

    > .sub-title {
      color: $grey5;
      margin-bottom: 0;
      font-size: 14px;
    }
  }

  > .options-select {
    display: flex;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid $grey2;

    &:last-child {
      padding-bottom: 0;
      border-bottom: 0;
    }

    > .label {
      > p {
        margin-bottom: 0;

        &.title {
          font-size: 16px;
          font-weight: 600;
          color: $black;
        }

        &.sub-title {
          font-size: 14px;
          color: $blue;
        }
      }
    }

    > .CheckBox {
      margin-left: auto;
    }
  }
}



.PayNow {
  background-color: $grey1;
  padding: 20px;
  border-radius: 24px;
  margin-bottom: 40px;

  > .header {
    padding-bottom: 20px;
    border-bottom: 1px solid $grey2;

    > .title {
      color: $blue;
      margin-bottom: 5px;
      font-size: 21px;
      font-weight: 600;
    }

    > .sub-title {
      color: $grey5;
      margin-bottom: 0;
      font-size: 14px;
    }
  }

  > .options-select {
    display: flex;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid $grey2;

    &:last-child {
      padding-bottom: 0;
      border-bottom: 0;
    }

    > .option-icon {
      margin-right: 15px;
      width: 50px;
    }

    > .label {
      > p {
        margin-bottom: 0;

        &.title {
          font-size: 16px;
          font-weight: 600;
          color: $black;
        }

        &.sub-title {
          font-size: 14px;
          color: $blue;
        }
      }
    }

    > .CheckBox {
      margin-left: auto;
    }
  }
}

.ReserveNow {
  background-color: $grey1;
  padding: 20px;
  border-radius: 24px;
  margin-bottom: 40px;

  > .header {
    padding-bottom: 20px;
    border-bottom: 1px solid $grey2;

    > .title {
      color: $blue;
      margin-bottom: 5px;
      font-size: 21px;
      font-weight: 600;
    }

    > .sub-title {
      color: $grey5;
      margin-bottom: 0;
      font-size: 14px;
    }
  }

  > .options-select {
    display: flex;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid $grey2;

    &:last-child {
      padding-bottom: 0;
      border-bottom: 0;
    }

    > .option-icon {
      margin-right: 15px;
      width: 50px;
    }

    > .label {
      align-self: center;

      > p {
        margin-bottom: 0;

        &.title {
          font-size: 16px;
          font-weight: 600;
          color: $black;
        }
      }
    }

    > .CheckBox {
      margin-left: auto;
    }
  }
}

.reserve-validation {
  display: inline-flex;
  margin-top: 15px;
  margin-bottom: 15px;
}

.reserve-validation-icon {
  width: 15px;
  height: 19px;
  font-family: FK Grotesk Neue;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: #EF3838;
}

.reserve-validation-msg {
  font-family: FK Grotesk Neue;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #EF3838;
}

.ReserveNow-validation, .PayNow-validation , .PaymentOptions-validation{
  margin-top: 7px;
  margin-bottom: 7px;
  margin-left: 6px;
  margin-right: 6px;
}

.confirm-msg {
  font-weight: bold;
  margin-left: 30px;
  color: black;
  margin-bottom: 0px;
}

.wire-confirm-msg {
  font-weight: bold;
  color: black;
  margin-bottom: 0px;
}