@import "src/styles/variables";

.white {
  color: $white;
}
.concierge-main {
  background-color: $black;
  padding-bottom: 90px;
  // flex:1;
}

.concierge-root {
  max-width: 882px;
  // max-width: $page-width;
  margin: auto;
  width: 100%;
  color: $grey1;
  padding-top: 100px;
  padding-bottom: 90px;
}

.logo {
  display: block;
  position: relative;
  width: 40%;
}

.concierge-title {
  color: $grey1;
  // font-weight: 700;
  // font-size: 72px;
  // line-height: 80px;
  // font-style: normal;
  text-align: left;
  // letter-spacing: -0.04em;
  // padding-top: 2.5%;
  // padding-bottom: 5%;
  h3 {
    margin-right: 1%;
  }
}

.con_ttle {
  padding-top: 25px !important;
  // position: unset !important;
  // margin-bottom: 0 !important;
  text-align: left !important;
  // display: block !important;
  // height: Auto !important;
  // font-size: 29px !important;
  font-weight: 600 !important;
  font-size: 36px !important;
  line-height: 46px !important;
}

.head-title {
  font-style: normal;
  font-weight: 700;
  font-size: 72px;
  line-height: 80px;
}
.head-sub-title {
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 46px;
}
.testimonial-scroll-icon {
  display: block;
  // margin-left: 0;
  // padding-top: 150px;
  // transform: translate(0);
  // margin-left: 15%;
  padding-top: 120px;
  transform: translate(-50%, 0);
  margin-left: 50%;
}

.concierge-subtitle {
  text-align: center;
  font-size: 200%;
  line-height: 20px;
}
.aircraft-concierge {
  position: relative;
  // margin-left: -10em;
  margin-top: -70px;
  img {
    width: 75%;
    margin-left: 50%;
    transform: translate(-50%, 0);
    margin-top: -20px;
    // width: 120%;
  }
}

.concierge-subphrase {
  text-align: left;
  width: 100%;
  padding: 0.5vw;
  // font-size: 160%;
  // line-height: 115%;
  margin: auto;
  // margin-top: 80px;
  color: #9ca2a9;
  // font-weight: bold;
}

.concierge-subphrase-title {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
}

.services-list {
  display: flex;
  justify-content: center;
  padding-top: 0;
  // padding-bottom: 43px;
}

.bottom {
  padding-top: 43px;
}

.concierge-service {
  width: 33%;
  text-align: center;
  img {
    margin-bottom: 25px;
  }
  p {
    font-weight: bold;
    font-size: 24px;
    line-height: 31px;
  }
  .info {
    cursor: pointer;
    font-size: 120%;
    color: #9ca2a9;
    margin-bottom: 0;
  }

  .info:hover {
    text-decoration: underline;
  }
}

.fullservice-section {
  // position: relative;
  max-width: 1440px;
  width: 100%;
  // height: 400px;
  // left: 0px;

  background: #000000;
  flex: none;
  order: 5;
  flex-grow: 0;
  margin: auto;
  margin-top: 300px;

  h2 {
    position: relative;
    width: 100%;
    // height: 160px;
    // top: 300px;
    // font-style: normal;
    // font-weight: bold;
    // font-size: 58px;
    // line-height: 65px;
    align-items: center;
    color: $grey1;
    text-align: center;
  }
}

.detail-section {
  // position: relative;
  max-width: 1440px;
  width: 100%;
  // height: 650px;
  left: 0px;
  // top: 290px;
  padding-top: 80px;
  background: #000000;
  flex: none;
  order: 5;
  flex-grow: 0;
  margin: auto;

  h3 {
    position: absolute;
    width: 499px;
    // left: -90px;
    // top: 190px;
    display: flex;
    align-items: center;
    color: $grey1;
  }
}

.concierge-statements {
  // margin: -65px auto;
  // padding: 0;
  // padding-bottom: 120px;
  // margin-left: 65%;
  margin-left: 212px;
  position: relative;
  .Statement {
    margin-bottom: 64px;
    .icon {
      max-width: 50px;
      max-height: 50px;
      width: auto;
      height: auto;
    }
    .text {
      margin-top: 32px;
      color: $white;
      // font-weight: 400;
    }
  }
}

@media ($mobile-media) {
  .concierge-root {
    width: 100% !important;
    padding-bottom: unset !important;
    padding-top: 20px;
  }
}

@media screen and ($mobile-media) {
  .concierge-title {
    width: 100vw;
    font-weight: 700;
    font-size: 48px;
    line-height: 48px;
    font-style: normal;
    text-align: center;
    padding: 14px;
    // letter-spacing: -0.04em;
    // padding-top: 2.5%;
    // padding-bottom: 5%;
    h3 {
      margin-right: 6%;
    }
  }

  .hidable {
    display: none;
  }

  .con_ttle {
    line-height: 30px;
  }

  .concierge-subphrase-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
  }

  .head-title {
    font-size: 48px;
    line-height: 48px;
  }
  .head-sub-title {
    font-size: 24px;
    line-height: 140%;
  }

  .services-list {
    flex-wrap: wrap;
    padding-bottom: 5%;
  }

  .concierge-service {
    width: 100%;
    padding: 5% 0;
    .info {
      font-size: 18px;
      word-break: break-word;
      padding: 0 10px;
    }
  }

  .concierge-subphrase {
    text-align: center;
    // width: 289px;
    font-size: 20px;
    line-height: 26px;
    font-weight: 600;
    margin-top: 18px;
    position: relative;
  }

  .testimonial-scroll-icon {
    transform: translate(-50%, 0);
    margin-left: 50%;
    padding-top: 30px;
  }
  .aircraft-concierge {
    margin-left: unset;
    position: relative;
    height: 150px;
    top: 20px;
    margin-top: unset;
    img {
      width: 100%;
      position: absolute;
    }
  }
  .detail-section {
    // height: 980px;
    padding-top: 65px;
    top: unset;
    flex: unset;
    flex-grow: unset;
    order: unset;
    flex-grow: unset;
    h3 {
      position: unset;
      width: 100%;
      // font-style: normal;
      // font-weight: 600;
      padding-left: 25px;
      padding-right: 25px;
      // font-size: 24px;
      // line-height: 140%;
    }
  }
  .statement-container {
    margin-left: unset !important;
    padding: 24px;
    padding-top: 70px;
  }
  .concierge-statements {
    margin-left: unset;
    .Statement {
      .icon {
        margin-left: 6%;
        transform: translate(-50%, 0);
      }
      .text {
        margin-top: 25px;
        font-weight: 600;
        // font-size: 20px;
        text-align: left;
        // line-height: 26px;
        font-size: 24px;
        line-height: 140%;
      }
    }
  }
  .fullservice-section {
    top: unset;
    flex: unset;
    flex-grow: unset;
    order: unset;
    flex-grow: unset;
    margin: unset;
    z-index: 1;
    display: block;
    margin-top: 210px;
    h2 {
      top: unset;
      line-height: 48px;
      font-size: 48px;
      font-style: normal;
    }
  }
}

@media screen and ($tablet-media-min) and ($tablet-media) {
  .concierge-title {
    width: 100vw;
    font-weight: 700;
    font-size: 48px;
    line-height: 48px;
    font-style: normal;
    text-align: center;
    padding: 14px;
    // letter-spacing: -0.04em;
    // padding-top: 2.5%;
    // padding-bottom: 5%;
    h3 {
      margin-right: 6%;
    }
  }

  .concierge-root {
    width: 100% !important;
    padding-bottom: unset !important;
    padding-top: 65px;
  }

  .hidable {
    display: none;
  }

  .head-title {
    font-size: 65px;
    line-height: 70px;
  }
  .head-sub-title {
    font-size: 26px;
    line-height: 140%;
  }

  .services-list {
    flex-wrap: wrap;
    padding-bottom: 5%;
    padding-top: 0;
  }

  .concierge-service {
    width: 100%;
    padding: 5% 0;
    .info {
      font-size: 18px;
    }
  }

  .concierge-subphrase {
    text-align: center;
    width: 75%;
    font-size: 20px;
    line-height: 26px;
    font-weight: 600;
    margin-top: 18px;
    position: relative;
  }

  .testimonial-scroll-icon {
    // position: absolute;
    // left: 0;
    transform: translate(-50%, 0);
    margin-left: 50%;
    padding-top: 100px;
  }
  .aircraft-concierge {
    margin-left: unset;
    position: relative;
    height: 250px;
    img {
      width: 100%;
      position: absolute;
    }
  }

  .detail-section {
    // height: 800px;
    top: unset;
    flex: unset;
    flex-grow: unset;
    order: unset;
    flex-grow: unset;
    padding-top: 50px;
    h3 {
      position: unset;
      width: 90%;
      font-style: normal;
      font-weight: 600;
      font-size: 40px;
      line-height: 44px;
      padding-left: 25px;
      padding-right: 25px;
    }
  }

  .statement-container {
    margin-left: unset !important;
    padding: 24px;
  }
  .concierge-statements {
    margin-left: unset;
    .Statement {
      .icon {
        margin-left: 4%;
        transform: translate(-50%, 0);
      }
      .text {
        margin-top: 25px;
        font-weight: 600;
        font-size: 20px;
        text-align: left;
        line-height: 26px;
      }
    }
  }
  .fullservice-section {
    top: unset;
    margin-top: unset;
    flex: unset;
    flex-grow: unset;
    order: unset;
    flex-grow: unset;
    margin: unset;
    z-index: 1;
    display: block;
    margin-top: 195px;
    // padding-top: 80px;
    h2 {
      top: unset;
      line-height: unset;
    }
  }
}

@media screen and ($tablet-media-min-hz) and ($tablet-media-hz) {
  .detail-section {
    // height: 900px;
    h3 {
      left: 3%;
      font-size: 44px;
      width: 450px;
    }
  }

  .aircraft-concierge {
    margin-left: unset;
    img {
      width: 100%;
    }
  }

  .fullservice-section {
    // margin-top: unset;
    margin-top: 195px;
  }

  .concierge-title {
    text-align: center;
  }
}
