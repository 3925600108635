@import 'src/styles/variables';

.leg-info{
    .header-container{
        background: $blue1 !important;
    }
    .trip-leg-header{
        max-width: 1440px;
        padding: 40px 90px;
        display: flex;
        margin: auto;
        flex-direction:row;
        justify-content: space-between;
        align-items: center;
        .leg-number{
            color: $white;
            font-weight: 700;
            margin: 0;
        }
        .category{
            margin:0;
            color: $white;
        }
    }
    .info-phone{
        .mobile-header{
            font-size:20px;
            background-color: $grey1;
            padding: 12px 20px;
            font-weight: 600;
            margin-bottom:0;
        }
        .travel-container{
            padding: 30px 0px;
        }
        .info-container{
            padding: 20px 20px;
            p{
                font-size:17px;
            }
            .time,.number{
                margin: 0;
            }
        }
        .table-container{
            padding: 0 20px;
            .row{
                padding: 12px 0;
                margin: 0;
                justify-content:space-between;
                p{
                    font-size:17px;
                    margin: 0;
                }
            }
            .crew{
                border-bottom: 1px solid $grey2;
            }
            .crew:last-child{
                border-bottom:none;
            }
        }
        .departure, .destination{
            padding: 0 20px;
            p{
                font-size:18px;
            }
            .place{
                padding-left: 5px;
                display: flex;
                width: 80%;
                .code{
                    font-weight: 700;
                    margin-right: 0.5rem;
                }
                .display{
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
            }
            .date-time{
                background: $blue1;
                padding:15px 8px;
                font-size: 15px;
                font-weight: 600;
                font-family: $font-medium;
                border-radius: 8px; 
                width:fit-content;
                max-width: 280px;
                margin-left:0px;
                color:$white;
            }
        }
        .FBO{
            padding: 0 20px;
            p{
                font-size:18px;
            }
            .Heading, .Spacer{
                padding-top: 10px;
                padding-left: 5px;
                font-size:16px;
                color:$grey3;
                font-weight: 600;
                font-family: $font-regular;
            }
            .airport, .airport-2{
                padding-left:5px;
                width: 60%;
                font-weight: 600;
                margin: 0;
            }
            .airport-2{
                padding-top: 40px;
            }
            .fbo-text{
                padding-left:5px;
                width: 60%;
            }
        }
    }
    .info{
        max-width: $page-width;
        margin: 46px auto;
        padding: 0 3%;
        .flight-info-1{
            display:grid;
            grid-template-columns: 2.4fr 2.4fr 1.2fr 1fr;
            margin-bottom: 36px;
            p{
                font-size:18px;
            }
            .Heading{
                padding-left: 5px;
                font-size:16px;
                color:$grey3;
                font-weight: 600;
                font-family: $font-regular;
            }
            .place{
                padding-left: 5px;
                display: flex;
                min-height: 68.8px;
                width: 80%;
                .code{
                    font-weight: 700;
                    margin-right: 0.5rem;
                }
                .display{
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
            }
            .time, .number{
                padding-left: 5px;
            }
            .date-time{
                background: $blue1;
                padding:15px 8px;
                font-weight: 600;
                font-family: $font-medium;
                border-radius: 8px;
                width: fit-content;
                max-width: 280px;
                font-size: 15px;
                margin-left:0px;
                // margin-right:20px;
                color:$white;
            }
        }
        .flight-info-2{
            display:grid;
            grid-template-columns: 1fr 1fr 1fr;
            p{
                font-size:18px;
            }
            .Heading, .Spacer{
                padding-left: 5px;
                font-size:16px;
                color:$grey3;
                font-weight: 600;
                font-family: $font-regular;
            }
            .airport, .airport-2{
                padding-left:5px;
                width: 60%;
                font-weight: 600;
                margin: 0;
            }
            .airport-2{
                padding-top: 40px;
            }
            .fbo-text{
                padding-left:5px;
                width: 60%;
            }
            
            .crew{
                display:grid;
                grid-template-columns:1fr 1fr 1.5fr;
                grid-column-gap:10px;
                p{
                    font-size: 17px;
                    margin: 0.5rem 0;
                }
                .head, .head-mid{
                    color:$grey3;
                    font-weight: 600;
                }
                .value-2,.value-3{
                    align-self: end;
                }
                .head-mid,.value-2{
                    text-align: center;
                    // margin-right: 20px;
                    width: 65px;
                }
                .head-1,.value-1{
                    min-width: 105px;
                    max-width: 120px;
                }
                .head-3,.value-3{
                    // text-align: end;
                    min-width: 125px;
                    max-width: 140px;
                }
                
            }
            .crew{
                border-bottom: 1px solid $grey2;
            }
            .crew:last-child, .crew:first-child{
                border-bottom:none;
            }
            
        }
        .Passengers-itinerary{
            max-width: $page-width;
            h5{
                margin:30px 0;
                font-weight: 900;
            }
            table{
                width: 100%;
                thead{
                    th{
                        color: $grey3;
                        text-align: center;
                        padding-bottom: 20px;
                    }
                }
                tbody{
                    td{
                        padding: 5px 0;
                        text-align: center;
                    }
                    tr{
                        border-bottom:$grey4 1px solid;
                    }
                    tr:last-child{
                        border-bottom:none;
                    }
                }
                .start{
                    text-align: start;  
                }
                .end{
                    text-align: end;
                }
                .number{
                    padding-right: 25px;
                }
            }
            .Empty{
                margin-top:10px;
                h3{
                    margin: 0 auto;
                    text-align: center;
                    color:$grey3;
                    font-family: $font-regular;
                }
            }
        }
        .Additional{
            h5{
                margin:30px 0;
                font-weight: 900;
            }
        }
    }
}
.notes-style {
    min-height: 100px;
    width: 100%;
    padding: 5px;
    border-radius: 15px;
    box-shadow: 0 0 1px 1px #00000057;
    border: 8px solid transparent;
    outline: none;

  }
.addon-sub {
    width: 15%;
    float:left;
    }
.addon-sub-symbol {
     padding-left: 1%;
     padding-right: 1%;
     float:left;
    }

    
.mob-notes-style {
    min-Height: 100;
    width: 60%;
    border-radius: 25px;
    padding: 20px;
  }
.note-text {
    padding-top: 10px;

  }
@media screen and ($tablet-media-min) and ($tablet-media){
    .leg-info{
        .trip-leg-header{
            padding: 40px 45px;
            .leg-number{
                font-size:35px;
            }
            h4{
                font-size: 20px;
            }
        }
        .info{
            padding: 0 5%;
            .flight-info-1{
                grid-template-columns: 1fr 1fr;
                grid-gap: 25px;
            }
            .flight-info-2{
                grid-template-columns: 1fr 1fr;
                grid-gap: 25px;
                .crew-container{
                    grid-column: 1 / -1;
                }
            }
        }
    }
}

@media screen and ($mobile-media){
    .leg-info{
        .trip-leg-header{
            padding: 30px 30px;
            .leg-number{
                font-size:20px;
            }
            h4{
                font-size: 15px;
            }
        }
        .info-phone{
            display: block !important;
        }
    }
}