@import 'src/styles/variables';

.MyAccount {
  > .body {
    min-height: 400px;
    padding: 50px 20px;

    h2 {
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 41px;
      padding-bottom: 20px;
    }
  }
}

.select::-webkit-scrollbar {
  width: 8px;
}

.select::-webkit-scrollbar-track {
  border-radius: 10px;
}

.select::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: $grey2;
}


.Excluded-Aircraft{
  max-width: $page-width;
  width:95%;
  margin: auto;

  .Button{
    max-width: 400px;
    margin: 0 auto;
    margin-top: 20px;
    display: block;
  }

  .aircraft-tabs{
    font-weight: 600;
    .active-btn{
      color: $blue;
    }
    .active-btn,.inactive-btn{
      cursor: pointer;
      width: 20%;
      text-align: center;
    }
  }

  .select{
    max-height: 600px;
    overflow-y: scroll;

    .item-container:hover input ~ .checkmark {
      background-color: $grey7;
    }
  
    /* When the checkbox is checked, add a black background */
    .item-container input:checked ~ .checkmark {
      background-color: #000;
    }

    .select-all:hover input ~ .checkmark {
      background-color: $grey7 !important;
    }
  
    /* When the checkbox is checked, add a black background */
    .select-all input:checked ~ .checkmark {
      background-color: #000 !important;
    }

    .item-container{
      margin: 0;
      display: flex;
      align-items: center;
      height: 100%;
      padding: 2%;
      color: $black;

      input {
        position: absolute;
        border: 0;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      .checkmark {
        border-radius: 100%;
        position: absolute;
        right: 20px;
        height: 20px;
        width: 20px;
        background-color: #eee;
      }
    
      /* Create the checkmark/indicator (hidden when not checked) */
      .checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }
    
      /* Show the checkmark when checked */
      input:checked ~ .checkmark:after {
        display: block;
      }
    
      /* Style the checkmark/indicator */
      .checkmark:after {
        left: 8px;
        top: 5px;
        width: 4px;
        height: 9px;
        border: solid white;
        border-width: 0 1px 1px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }

    .select-all{
      margin: 0;
      display: flex;
      align-items: center;
      padding: 2%;
      height: 80px;
      color: $black;
      font-weight: 600;
      font-size: 20px;
      background-color: $grey1;
      .checkmark{
        background-color:$grey2 !important;
      }
      input {
        position: absolute;
        border: 0;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      .checkmark {
        border-radius: 100%;
        position: absolute;
        right: 20px;
        height: 20px;
        width: 20px;
        background-color: #eee;
      }
    
      /* Create the checkmark/indicator (hidden when not checked) */
      .checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }
    
      /* Show the checkmark when checked */
      input:checked ~ .checkmark:after {
        display: block;
      }
    
      /* Style the checkmark/indicator */
      .checkmark:after {
        left: 8px;
        top: 5px;
        width: 4px;
        height: 9px;
        border: solid white;
        border-width: 0 1px 1px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }

    .list{
      border: 0;
      .kn-item__item___kQ2Ll{
        transition: none;
      }
      .kn-list__list___22Wuc{
        overflow-y: hidden !important;
      }
      .kn-item__with_border___3n9sw{
        background-color: $grey1;
        border-bottom: 0;
        
        .kn-item_label__label___2fJ5p{
          color:$black;
          font-weight:600;
          padding-left: 20px;
          font-size: 20px;
        }
      }
      .kn-list__list_item___1mDV9{
        border-bottom: 1px solid $grey4;
        .kn-item_label__label___2fJ5p{
          padding-left: 20px;
          color:$black;
        }
      }
      .kn-list__list_item___1mDV9:last-child{
        border-bottom: 0;
      }
    }
  }
}

.MyAccountHeader {
  background-color: $black;
  color: $white;
  padding-top: 65px;
  padding-bottom: 43px;

  > h1 {
    max-width: $page-width;
    width:95%;
    margin: auto;
    font-size: 60px;
    font-weight: 600;
    line-height: 10px;
    padding-bottom: 10px;
  }
}

.PersonalInfo {
  max-width: $page-width;
  width:95%;
  margin: auto;

  form{
    .Button{
      margin-top:20px;
      max-width: 400px;
    }
  }

  .personal-info-inputs{

    border-top: 1px solid $grey2;
  }

  .phone-input{
      border: 0;
      width: 100%;
      &:focus {
        outline: none;
      }
  }

  .full-width-container {
      border: 0;
      font-size:18px;
      width: 100%;

      &:focus {
        outline: none;
      }
    }

  .key-value {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    border-bottom: 1px solid $grey2;

    .key {
      margin-bottom: 0;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: $grey3;
      padding-bottom: 5px;
    }

    .value {
      margin-bottom: 0;
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
    }
  }

  .link-key-value {
    display: flex;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 20px;
    border-bottom: 1px solid $grey2;
    cursor: pointer;

    .title {
      margin-bottom: 0;
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
    }

    svg {
      margin-left: auto;
    }
  }
}

.AbReward {
  width: $page-width;
  margin: auto;

  .empty-container{
    margin: clamp(20px,12%,30px) 0 0 0 
  }

  .available-balance {
    background-color: $blue;
    padding: 20px;
    border-radius: 24px;
    color: $white;

    .title {
      margin-bottom: 0;
      padding-bottom: 5px;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
    }

    .balance {
      margin-bottom: 0;
      font-weight: 600;
      font-size: 24px;
      line-height: 26px;
      padding-bottom: 5px;
    }

    .sub-title {
      margin-bottom: 0;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      //color: rgba(0, 0, 0, 0.5);
      color: $white;
    }
  }

  .key-value {
    padding: 20px;
    border-bottom: 1px solid $grey2;
    display: flex;

    .key {
      margin-bottom: 5px;
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
    }

    .value {
      margin-bottom: 0;
      color: $blue;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
    }

    .angle-right {
      margin-left: auto;
      margin-top: 10px;
    }
  }
}

.Wallet {
  max-width: $page-width;
  width:95%;
  margin: auto;

  .empty-container{
    margin: clamp(20px,12%,30px) 0 0 0 
  }

  .available-balance {
    display: flex;
    align-items: center;
    background-color: $blue;
    padding: 20px;
    border-radius: 24px;
    color: $white;

    .title {
      margin-bottom: 0;
      padding-bottom: 5px;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
    }

    .balance {
      margin-bottom: 0;
      font-weight: 600;
      font-size: 24px;
      line-height: 26px;
      padding-bottom: 5px;
    }

    .sub-title {
      margin-bottom: 0;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      //color: rgba(0, 0, 0, 0.5);
      color: $white;
    }

    button {
      margin-left: auto;
      min-height: 60px;
      min-width: 140px;
    }
  }

  .key-value {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    border-bottom: 1px solid $grey2;
    display: flex;

    &:nth-child(2) {
      border-top: 1px solid $grey2;
    }

    .key {
      margin-bottom: 0;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: $grey3;
      padding-bottom: 5px;
    }

    .value {
      margin-bottom: 0;
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
    }

    .funds-tans {
      margin-left: auto;
      align-self: center;
      min-width: 100px;

      &.add {
        background-color: $blue;
        color: $white;
        padding: 5px 12px;
        border-radius: 20px;
      }

      &.sub {
        background-color: $red;
        color: $white;
        padding: 5px 12px;
        border-radius: 20px;
      }
    }
  }
}

.Notification {
  max-width: $page-width;
  width:95%;
  margin: auto;
  z-index: 99;

  .preference-action {
    display: flex;
    margin-left: auto;
  }

  .preference-container {
    
    .Button{
      max-width: 400px;
    }

    .disclaimer{
      color:$grey3;
    }

    .preference-tile {
      display: flex;
      margin-top: 25px;
      margin-bottom: 25px;

      .label {
        margin-bottom: 0;
        align-self: center;
      }
    }
  }

  .notification-history-tile {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    border-bottom: 1px solid $grey2;
    display: flex;

    &:nth-child(3) {
      border-top: 1px solid $grey2;
    }

    .key {
      margin-bottom: 0;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: $grey3;
      padding-bottom: 5px;
    }

    .value {
      margin-bottom: 0;
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
    }

    .funds-tans {
      margin-left: auto;
      align-self: center;

      &.add {
        background-color: $blue;
        color: $white;
        padding: 5px 12px;
        border-radius: 20px;
      }

      &.sub {
        background-color: $red;
        color: $white;
        padding: 5px 12px;
        border-radius: 20px;
      }
    }
  }

}

.Passengers {
  max-width: $page-width;
  width:95%;
  margin: auto;

  .action {
    padding: 20px;
    display: flex;
    cursor: pointer;
    margin-right: 20px;

    .label {
      margin-left: 20px;
      margin-top: 8px;
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
      margin-bottom: 0;
    }
  }

  .tile {
    padding: 20px;
    border-bottom: 1px solid $grey2;

    &:first-child {
      border-top: 1px solid $grey2;
    }

    .name {
      margin-bottom: 0;
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
    }

    .info {
      margin: 0 5px 0 0;
      font-size: 13px;
    }
  }
}

@media screen and ($tablet-media-min) and ($tablet-media) {
  .MyAccountHeader > h1,
  .Wallet,
  .PersonalInfo,
  .Passengers,
  .AbReward {
    width: 95%;
  }
}


.EditPreferenceModal {
  .body {

    .preference-tile {
      display: flex;
      margin-top: 25px;
      margin-bottom: 25px;

      .label {
        margin-bottom: 0;
        align-self: center;
      }
    }
  }
}

@media screen and ($mobile-media) {
  .MyAccountHeader {
    > h1 {
      width: 100%;
      padding: 20px;
    }
  }
  .Excluded-Aircraft{
    .aircraft-tabs{
      overflow-x: none;
      width: 100%;
    }
    .inactive-btn,.active-btn{
      font-size: 15px;
    }
  }
  .Notification {
    padding: 20px;
    width: 100%;
  }
  .PersonalInfo {
    width: 100%;
  }
  .MyAccount {
    .body {
      h2 {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
  .Passengers {
    width: 100%;
  }
  .AbReward {
    width: 100%;
    padding: 10px;
  }
  .Wallet {
    width: 100%;
    padding: 10px;
    .available-balance {
      button {
        min-width: 100px;
      }
    }
  }
}