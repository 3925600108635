@import "src/styles/variables";

.CheckBox {
  height: 40px;
  width: 40px;
  border-radius: 12px;
  margin-right: 10px;
  align-self: center;
  border: 2px solid $grey2;
  padding: 3px;

  &.yellow-check {
    > .checked {
      background-color: $yellow;
    }
  }

  &.blue-check {
    > .checked {
      background-color: $blue;
    }
  }

  &.checkable {
    cursor: pointer;
  }

  > .checked {
    height: 30px;
    width: 30px;
    border-radius: 8px;
  }

  &.disabled {
    cursor: not-allowed;
  }
}

