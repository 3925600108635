@import "src/styles/variables";

.PaymentSuccess {
  min-height: 800px;
  background-color: $blue;
  margin: auto;
  text-align: center;

  > h1 {
    color: $white;
    padding-bottom: 15px;
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
    max-width: 360px;
    margin: auto;
  }

  > h2 {
    color: $white;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    max-width: 360px;
    margin: auto;
    padding-bottom: 15px;
  }

  > h3 {
    color: $white;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    max-width: 360px;
    margin: auto;
    padding-bottom: 15px;
  }

  .booking-content{
    color: $white;
  }

  .check-icon {
    margin-top: 50px;
    width: 120px;
    margin-bottom: 30px;
  }

  > .booking-id {
    background-color: $dark-blue;
    border-radius: 24px;
    padding: 20px;
    width: 335px;
    color: $white;
    display: flex;
    margin: 20px auto;

    > p {
      margin-bottom: 0;

      &.label {
      }

      &.value {
        margin-left: auto;
      }
    }
  }

  > button {
    width: 335px;
    margin-bottom: 100px;
  }

  > .account-info {
    background-color: $dark-blue;
    border-radius: 24px;
    padding: 20px;
    width: 335px;
    color: $white;
    margin: 20px auto;

    > .tile {
      display: flex;
      width: 100%;
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }

      > p {
        margin-bottom: 0;

        &.label {
        }

        &.value {
          margin-left: auto;
        }
      }
    }
  }
}