@import "src/styles/variables";

.BookAFlight {
  min-height: 600px;
  width: 100%;
  // max-width: $page-width;
  .planeCarousel {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .disable-amt-container {
    width: 40%;
  }

  .show-main-summary {
    visibility: visible;
  }

  .show-phn-summary {
    display: none;
  }

  .disabled-aircraft {
    margin-top: 70px;
    .details-container {
    display: flex;
    width: 100%;
    max-width: 968px;
    margin: -15px auto 30px;  
    } 
    .info-container {
      margin: 0 auto;
    }
  }
  .book-flight-summary {
    width: 100%;
    max-width: 1080px;
    margin: 66px auto;
    display: flex;
  }

  .plane-meta {
    width: $page-width;
    display: flex;
    margin: 10px auto auto;

    button {
      &.blue-btn {
        float: right;
      }
    }
  }

  .details-container {
    display: flex;
    width: 85%;
    max-width: $page-width;
    margin: 75px auto 30px;
    .title-contact {
      font-size: 35px !important;
      font-weight: 600;
    }

    .info-container {
      width: 70%;
      margin-right: 25px;
      // margin: 0 auto;
      .unavailable {
        margin-bottom: 30px;
        border-radius: 24px;
        background-color: $grey3;
        padding: 20px 20px;
        .info-text {
          color: $white;
          font-size: 15px;
        }
        .header {
          margin-bottom: 10px;
          display: flex;
          flex-direction: row;
          .icon {
            align-self: center;
          }
          .title {
            font-size: 35px !important;
            font-weight: 600;
            color: $white;
            margin-bottom: 0;
            padding-left: 10px;
            font-family: $font-medium;
          }
        }
      }

      .info-text {
        font-weight: normal;
      }
    }

    .amount-containers {
      margin-left: 133px;
    }
  }

  .ApplicationTab .tab-container {
    width: 95%;
    max-width: $page-width;
  }

  .good-deal-modal {
    > img {
      width: 100%;
    }

    > .plane-type {
      text-align: center;
      font-size: 25px;
      font-weight: bold;
    }

    > button {
      display: flex;
      align-items: center;
      margin: 30px auto auto;
    }

    > .info-text {
      color: $grey3;
      width: 70%;
      text-align: center;
      margin: auto;
      padding-top: 30px;
      padding-bottom: 50px;
    }

    > .info-text-recommended {
      color: $grey3;
      width: 85%;
      text-align: center;
      margin: auto;
      padding-top: 30px;
      padding-bottom: 50px;
    }
  }
}

.BookAFlightHeader {
  background-color: $black;
  color: $white;
  padding-top: 20px;
  padding-bottom: 30px;
  padding-right: 15px;

  // > h1 {
  //   width: $page-width;
  //   margin: auto;
  //   font-size: 60px;
  //   font-weight: 600;
  //   line-height: 10px;
  //   padding-bottom: 43px;
  // }

  > .head-title {
    width: 95%;
    max-width: $page-width;
    margin: auto;
    margin-bottom: auto;
    margin-bottom: 43px;
    font-size: 72px;
    line-height: 80px;
  }

  > .route-info-container {
    width: 95%;
    max-width: $page-width;
    margin: auto;
    background-color: $grey5;
    padding: 10px;
    border-radius: 24px;
    display: flex;
    cursor: pointer;

    > img {
      width: 40px;
    }

    .grey-edit-circle-filled {
      cursor: pointer;
    }

    .route-info {
      display: flex;
      text-align: center;
      align-items: center;
      margin: auto;

      > p {
        margin-bottom: 0;

        &.airport {
          font-size: 21px;
          padding-left: 10px;
          padding-right: 10px;
          font-weight: 600;
        }

        &.suffix {
          font-size: 15px;
          font-weight: normal;
          padding-top: 3px;
        }
      }
    }
  }
}

.BookAFlight {
  .plane-meta {
    width: 95%;
    max-width: $page-width;
  }
}

.PlaneCarousel {
  width: 95%;
  max-width: $page-width;
}

.peak-style {
  margin-top: -100px;
  margin-bottom: 30px;
  border-radius: 24px;
  background-color: $error-red;
  padding: 20px 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  .info-text {
    color: $white;
    font-size: 15px;
  }
  .peak-headline {
    // text-align: center;
    color: #FFFFFF;
    margin-bottom: 5px;
    font-size: 21px;
    font-weight: 600;
    margin-left:48px;
  }
  .header {
    display: flex;
    flex-direction: row;
    .icon {
      width: 29px;
      height: 26.3px;
      color: $primary-gray;
      align-self: center;
      margin-top: -25px;
    }
    .peak-msg {
      margin-left: 18px;
      color: #FFFFFF;
      margin-bottom: 5px;
      font-size: 14px!important;
      margin-right: 15px;
      margin-top: -1px;
      width: 63%;
    }
  }
}
.peak-mob {
  display: none;
}
.peak-web {
  visibility: visible;
}

@media screen and ($mobile-media) {
  .BookAFlightHeader {
    > .route-info-container {
      width: 100%;
    }

    > h1 {
      width: 100%;
    }

    padding: 20px;
  }

  .show-main-summary {
    visibility: hidden;
    display: none;
  }

  .show-phn-summary {
    display: block!important;
  }


  .BookAFlight {
    .disable-amt-container {
      width: 100%;
    }
    .details-container {
      width: 85%;
      flex-wrap: wrap;

      .info-container {
        width: 95%;
        margin: auto;
      }

      .amount-containers {
        width: 100%;
        margin-right: 20px;
        margin-left: 4px;
        margin-top: 20px;
      }
    }
    .book-flight-summary {
      display: block;
      width: 100%
    }
  }

  .disabled-aircraft {
    margin-top: 70px;
    .details-container {
    margin: 30px auto 30px!important;  
    } 
  }
  .peak-mob {
    display: inline!important;  
  }
  .peak-web {
    display: none!important;  
    visibility: hidden!important;  
  }
  .peak-style {
    margin-top: 15px;
    .peak-msg {
      width: 100%!important;  
    }
  }
}

.PlaneCarousel {
  .image-slide {
    width: 100%;
  }
}

@media screen and ($tablet-media-min) and ($tablet-media) {
  .BookAFlightHeader > h1 {
    width: 95%;
  }

  .show-main-summary {
    visibility: hidden;
    display: none;
  }

  .show-phn-summary {
    display: block!important;
  }

  .BookAFlightHeader > .route-info-container {
    width: 95%;
  }

  .BookAFlight {

    .disable-amt-container {
      width: 100%;
    }

    .plane-meta {
      width: 95%;
    }

    .details-container {
      flex-wrap: wrap;
      flex-direction: column-reverse;
      width: 90%;
      margin-left: 30px;

      .amount-containers,
      .info-container {
        width: 95%;
        margin: 3%;
      }
      .amount-containers {
        margin-left: 4px;
      }        
    }

    .book-flight-summary {
      display: block;
      width: 85%
    }
  }

  .PlaneCarousel {
    width: 100%;

    .image-slide {
      height: 310px;
      width: 100%;
    }
  }

  .ApplicationTab .tab-container {
    width: 95%;
  }

  .book-flight-summary {
    display: block;
    width: 85%
  }

  .info-container {
    .unavailable {
      width: 95%;
    }
  }
  .peak-mob {
    display: inline;
  }
  .peak-web {
    display: none;
    visibility: hidden;
  }
  .peak-style {
    margin-top: 15px;
    .peak-msg {
      width: 75%!important;  
    }
  }
}

@media screen and ($tablet-media-min-hz) and ($tablet-media-hz) {
  .BookAFlightHeader > h1 {
    width: 95%;
  }

  .BookAFlightHeader > .route-info-container {
    width: 95%;
  }

  .BookAFlight {
    .disable-amt-container {
      width: 100%;
    }

    .plane-meta {
      width: 95%;
    }

    .details-container {
      flex-wrap: wrap;
      flex-direction: column-reverse;
      width: 90%;

      .amount-containers,
      .info-container {
        width: 95%;
        margin: 3%;
      }
    }

    .book-flight-summary {
      display: flex;
      width: 85%
    }
  }

  .PlaneCarousel {
    width: 95%;

    .image-slide {
      height: 310px;
      width: 95%;
    }
  }

  .ApplicationTab .tab-container {
    width: 95%;
  }

  .book-flight-summary {
    display: flex;
    width: 85%
  }
  .peak-style {
    margin-top: -50px;
  }
}


