@import "variables";

.primary-headline-h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 72px;
  line-height: 115%;
  // font-family: $font-bold;
}

.headline-h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 110%;
  // font-family: $font-bold;
}

.headline-h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 120%;
  // font-family: $font-medium;
}

.subtitile-h4 {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 140%;
  // font-family: $font-medium;
}

.subtitile-h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
  // font-family: $font-medium;
}

.body-font-p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  // font-family: $font-regular;
}

.airbook-logo {
  width: 38px;
  height: 39.25px;
}

.subtitle-space {
  margin-top: 32px;
}

.body-space {
  margin-top: 24px;
}

.action-space {
  padding-top: 48px;
}

@media screen and ($mobile-media) {
  .headline-h1 {
    font-size: 48px;
    line-height: 120%;
    letter-spacing: -0.01em;
  }

  .headline-h2 {
    font-size: 40px;
    line-height: 110%;
    letter-spacing: -0.02em;
  }

  .headline-h3 {
    font-size: 24px;
    line-height: 140%;
  }

  .subtitile-h4 {
    font-size: 20px;
    line-height: 140%;
  }

  .subtitile-h5 {
    font-size: 18px;
    line-height: 140%;
  }

  .airbook-logo {
    width: 19.36px;
    height: 20px;
  }
}
