@import 'src/styles/variables';

.Itinerary{
    .header-container{
        background: $black;
    }
    .download{
        display: flex;
        justify-content: center;
        margin-bottom:60px;
        .Button{
            width: 400px;
        }
        .disabledbutton{
            cursor: not-allowed
        }
    }
    .trip-header{
        max-width: 1440px;
        padding: 40px 90px;
        margin: auto;
        display: flex;
        flex-direction:row;
        justify-content: space-between;
        align-items: center;
        .bookingid{
            color: $white;
            font-weight: 700;
        }
        .legs-heading{
            display: flex;
            .legs{
                color:$grey7;
            }
        }
        .logo{
            height:91px;
            width:auto;
            margin-right: 15px;
        }
    }
    .basic-info{
        margin: 30px auto;
        max-width: $page-width;
        .info-subheading{
            color: $black;
            font-size:18px;
            font-family: $font-regular;
            font-weight: 700;
            padding-left: 20px;
        }
    }
    .custInfo{
        margin-bottom: 50px !important;
    }
    .info-box{
        padding: 10px 30px;
        margin-bottom: 20px;
        background-color: $grey4;
        border-radius: 12px;
        .row{
            padding: 5px 0;
            display: flex;
            flex-direction: row;
            justify-content:space-between;
            a,p{
                margin: 0;
                color: $black;
            }
            a,a:hover{
                text-decoration: none;
            }
            .key{
                font-weight: 600;
            }
        }
    }
    .end{
        margin: 40px 0;
        margin-bottom: 0;
        p{
            text-align: center; 
        }
        a, a:hover{
            text-decoration: none;
        }
    }
}

@media screen and ($tablet-media-min) and ($tablet-media){
    .Itinerary{
        .trip-header{
            padding: 40px;
            .bookingid{
                font-size: 35px;
            }
        }
        .basic-info{
            padding: 0 20px;
        }  
        .logo{
            height: 75px !important;
        }
    }
}

@media screen and ($mobile-media){
    .Itinerary{
        .download{
            .Button{
                width: 300px;
            }
        }
        .trip-header{
            padding: 20px;
            .logo{
                display: none;
            }
            .bookingid{
                font-size: 180%;
            }
            .legs-heading{
                .legs{
                    font-size:125%;
                }
            }
        }
        .basic-info{
            padding: 0 20px;
        }  
    }
}