@import "src/styles/variables";

.LightTextPanel {
  padding-top: 20px;
  padding-bottom: 20px;

  > svg {
    margin-bottom: 10px;
  }

  > .title {
    color: $grey3;
    margin-bottom: 5px;

    &.large-size {
      font-size: 16px;
    }

    &.small-size {
      font-size: 13px;
    }
  }

  > .body {
    color: $grey3;
    font-size: 14px;
    margin-bottom: 5px;
  }
}