@import "src/styles/variables";

.PayWithCreditCard {
  .term-agreement-title{
      p{
        font-size:14px;
        color:$grey5;
        font-weight: 600;
        margin-bottom: 10px;
      }
    }
     .term-agreement{
      display: flex;
      flex-direction: row;
      .terms{
        font-size:14px;
        color:$grey6;
        a{
          text-decoration: underline;
          color:$grey5;
          font-weight: 900;
        }
      }
    }
  .title {
    font-weight: normal;
    font-size: 30px;
    line-height: 140%;
    text-align: center;
  }

  .info {
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: $grey3;
  }

  .sub-title {
    font-weight: 600;
    font-size: 17px;
    line-height: 19px;
    color: $grey5;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  ._loading_overlay_overlay {
    position: fixed;
  }
}

 