@import 'src/styles/variables';

.gen-tem-light-section {
  // height:80vh;
  h1 {
    font-size: 72px;
    font-style: normal;
    font-weight: bold;
    line-height: 80px;
    text-align: center;
    color: $black;
    width: 100%;
    height: fit-content;
    display: flex;
    padding-top: 7%;
    align-items: center;
    justify-content: center;
  }

  div {
    // max-width: 600px;
    width: 100%;
    margin:2.5% auto 8% auto;
    height: 100%;
    left: 420px;
    top: 390px;
    font-size: 16px;
    line-height: 20px;
    color: $black;
    p{
      position: relative;
      top:5%;
      // font-size:120%;
      width:60vw;
      text-align: center;
      padding:2%;
      margin: 0 auto;
    }
  }
}

@media screen and ($mobile-media) {
  .gen-tem-light-section {
    h1 {
      font-size: 48px;
      line-height: 100%;
      letter-spacing: -0.01em;
      height: 175px;
    }


    div {
      width: 90%;
      margin: 5% 20px;
      height: 700px;
      p{
        width:100% !important;
      }
    }
  }
}
