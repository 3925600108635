@import "src/styles/variables";

.PlanInfo {
  background-color: $grey1;
  padding: 20px;
  border-radius: 25px;
  margin-bottom: 30px;

  .icon-info-container {
    padding-bottom: 20px;
    border-bottom: 1px solid $grey2;
    display: flex;

    .num-icon {
      height: 40px;
      width: 40px;
      border-radius: 20px;
      background-color: #D5DCE7;
      text-align: center;
      padding-top: 7px;
      font-size: 18px;
      font-weight: 600;

      &.ml {
        margin-left: 20px;
      }
    }

    .text {
      margin-bottom: 0;
      align-self: center;
      font-size: 16px;
      padding-left: 10px;
    }
  }

  .text-container {
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid $grey2;
  }

  .facilities-container {
    display: flex;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid $grey2;
    padding-left: 10px;

    &:last-child {
      border-bottom: 0;
      padding-bottom: 0;
    }

    p {
      padding-left: 20px;
      margin-bottom: 0;
    }
  }
}