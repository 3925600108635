@import "src/styles/variables";

.Button {
  border: 0;
  border-radius: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 500;

  &.black-btn {
    background-color: $black;
    color: $white;
  }

  &.yellow-btn {
    background-color: $yellow;
    color: $black;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  }

  &.blue-btn {
    background-color: $blue;
    color: $white;
  }

  &.red-btn {
    background-color: $red;
    color: $white;
  }

  &.red-disable-btn {
    background-color: $red;
    color: $white;
    opacity: .5;
    font-weight: 600;
    border-radius: 5px;
    height: 45px!important;
    font-size: 15px!important;
    margin-top: 0px;
  }

  &.warning-red-btn {
    background-color: $red;
    color: $white;
    font-weight: 600;
    border-radius: 5px;
    height: 45px!important;
    font-size: 15px!important;
    margin-top: 0px;
  }

  &.link-blue-btn {
    background-color: inherit;
    font-weight: 500;
    color: $blue;
  }

  &.outline-grey-btn {
    border: 1px solid $grey3;
    color: $grey3;
    background-color: inherit;
  }

  &.fullWidth {
    width: 100%;
  }

  &.large-btn {
    font-size: 17px;
    height: 50px;
    padding-left: 14px;
    padding-right: 14px;
  }

  &.social-btn {
    width: 100%;
    height: 90px;
    border-radius: 30px;
  }

  &.small-btn {
    font-size: 14px;
    height: 40px;
    padding-left: 15px;
    padding-right: 15px;
  }

  &.grey-btn {
    background-color: $grey1;
    color: $black;
  }

  &.dark-grey-btn {
    background-color: $grey4;
    color: $black;
  }

  &.white-btn {
    background-color: $white;
    color: $black;
  }

  &.white-link-btn {
    background-color: inherit;
    border: 0;
    color: $white;
  }

  &.yellow-link-btn {
    background-color: inherit;
    border: 0;
    color: $yellow;
    width: 151px;
    margin-top: -7px;
    margin-left: -10px;
  }

  &.white-blue-btn {
    background-color: $white;
    color: $blue;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  }

  &.extra-small-btn {
    font-size: 10px;
    //height: 20px;
    padding-left: 7px;
    padding-right: 7px;
    margin-top: 0;
    margin-bottom: 0;
  }

  &.link-btn {
    color: $grey3;
    background-color: inherit;
  }

  &.continue-btn {
    color: $grey3;
    background-color: $grey3;
    color: white;
    pointer-events: none;
  }

  &.blue-link-btn {
    color: $blue;
    background-color: inherit;
  }

  &.link-decoration-btn {
    background-color: inherit;
    font-weight: 500;
    color: $blue;
    text-decoration-line: underline;
    text-decoration-color: $blue;
  }
}