@import 'src/styles/variables';

.promo{
    background-color: $black;
    .search-flight {
        position: static;
        max-width: 1440px;
        width: 100vw;
        height: 810px;
        max-height: 900px;
        left: 0px;
        top: 80px;
        background: $black;
        flex: none;
        order: 1;
        flex-grow: 0;
        margin: auto;
      }
      .left-section-promo{
        margin-top: 6%;
        padding-top:30px;
        padding-left:30px;
        max-width: 65%;
        .logo {
          display: block;
          position: relative;
          width: 80% !important;
        }
        h4{
            color: $white;
            font-weight: 600;
            padding-top: 47px;
        }
        h5 {
            max-width: 534px !important;
            padding-top: 45px;
            font-style: normal;
            font-size: 16px;
            line-height: 20px;
            color: $white;
            a{
                color: $white;
                text-decoration: underline;
            }
        }
        .terms{
            max-width: 350px !important;
        }
        .download{
            display: flex;
            padding-top: 34px;
            .app-img-link{
              margin-right: 20px;
              
            }
            .app-img{
              width:100%;
            }
          }
    }
    .promo-signup-form{
        max-width: 35%;
        margin-top:6%;
        height: 600px;
        width: 450px;
        background-color: #fff;
        border-radius: 20px;
        padding:58px;
        h2{
            font-weight: 600;
        }
        .full-width-container {
            border: 0;
            padding-top: 20px;
            padding-bottom: 20px;
            border-bottom: 1px solid $grey5;
            width: 100%;
            color: $grey3;
      
            &:focus {
              outline: none;
            }
        }
        .password-container{
            position: relative;
            .eye-container{
                position: absolute;
                width: fit-content;
                top: 30%;
                right: 5%;
            }
        }
        .Button{
            margin-top:40px;
        }
    }
    .search-img {
        max-width: 1380px;
        width: 100%;
        height: 810px;
        max-height: 900px;
        margin: auto;
        padding: 0 2%;
        display: flex;
        flex-wrap: nowrap;
        box-shadow: 0px 1000px rgba(0, 0, 0, 0.35) inset;
        background-image: url('../../assets/hp-hero-img.png');
      
      }
}

@media screen and ($mobile-media) {
    .promo{
        .search-flight{
            max-height: none;
            height: 1300px !important;
            .left-section-promo{
                padding-right: 30px !important;
                h5{
                    font-size: 15px;
                    line-height: unset !important;
                }
            }
          }
          .search-img {
            height: 1300px !important;
            flex-wrap: wrap !important;
            height: 1100px;
            max-height: none;
            margin: 0;
            justify-content:center;
            padding: 0;
            background-size: cover;
            background-position: -150px;

            .left-section-promo, .promo-signup-form{
                max-width: 100%;
            }

            .Button{
                margin-top: 25px;
            }

            .promo-signup-form{
                height: 500px;
                max-width: 80%;
                padding: 24px;
            }
        
            .logo {
              width: 90%;
              max-width: 630px;
            }
        
            h5 {
              top: 20%;
              font-size:5vw;
            }
          }          
    }
}

@media screen and ($tablet-media-min) and ($tablet-media){
    .promo{
        .search-flight{
            .left-section-promo{
                max-width: 58%;
                padding-right: 20px;
            }
            .promo-signup-form{
                max-width:42%;
                height: 540px;
                padding: 27px;
            }
        }
    }
}