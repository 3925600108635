@import "src/styles/variables";

.Searching {
  // flex:1;
  .search-main {
    width: 350px;
    height: 154px;
    padding: 10% 0;
    margin: auto;

    img {
      display: block;
      width: 90px;
      height: 95px;
      position: relative;
      margin: 0 auto;
      top: -20px;
    }

    p {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      text-align: center;
      line-height: 22px;
      color: white;
      margin: 0 auto;
    }
  }

  .guidelines-section {
    display: flex;
    width: 50%;
    height: 430px;
    margin: auto;
    padding: 100px 20px;

    .guidelines-column {
      img {
        margin-bottom: 20px;
      }
    }

    div {
      margin: 30px;
    }

    h5 {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 19px;
      color: white;
    }

    p {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: white;
    }
  }

}

@media screen and ($mobile-media) {
  .Searching {
    .search-main {
      img {
        position: static;
        margin-bottom: 20px;
      }

      text-align: center;
    }

    .guidelines-section {
      width: 100%;

      div {
        margin: 10px;
      }
    }
  }
}