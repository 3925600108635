@import 'src/styles/variables';

.pvt-fly-card-title {
  width: 463px;
  // height: 46px;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 46px;
  padding: 0 20px;
  // display: flex;
  // align-items: center;
  color: $grey1;
}

.pvt-fly-card-content {
  max-width: 320px;

  h5 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    color: $grey1;
  }

  p {
    font-size: 16px;
    line-height: 20px;
    color: #9ca2a9;
  }
}

.pvt-fly-card-img {
  width: 50px;
  height: 50px;
}

.pvt-fly-card {
  position: static;
  max-width: 1300px;
  width: 100%;
  left: 91px;
  top: 56px;
  flex: none;
  order: 0;
  flex-grow: 0;
  display: flex;
  justify-content: space-around;
  margin: 10px auto;
  padding: 25px 0;
  flex-wrap: nowrap;
}

@media screen and ($mobile-media) {
  .pvt-fly-card-img {
    position: relative;
    width: 50px;
    height: 50px;
    left: 20px;
    top: 0px;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 20px 0px;
  }

  .pvt-fly-card-content {
    width: 100%;
    max-width: none;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #9ca2a9;
    padding: 10px 20px;
  }

  .pvt-fly-card-title {
    width: 100%;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 31px;
    padding: 5px 20px;
  }

  .pvt-fly-card {
    padding: 0;
    flex-wrap: wrap;
    position: static;
    width: 100%;
    left: 91px;
    top: 56px;
    flex: none;
    order: 0;
    flex-grow: 0;
    display: flex;
    justify-content: flex-start;
    margin: auto;
    padding: 25px 0;
  }
}

@media screen and ($tablet-media-min) and ($tablet-media) {
  .pvt-fly-card {
    justify-content: space-evenly;
    padding: 25px 10px;
  }
  .pvt-fly-card-title {
    width: 38%;
    font-size: clamp(25px,4vw,36px)
  }
}
