@import "../../styles/variables.scss";

.popular-trip-main {
  background-color: #000;
  overflow: hidden;
  .PopularTripBooking {
    //background-image: url('../../assets/hp-hero-img.png');
    // padding: 50px 0;
    // background-position-y: center;
    .SearchFlightForm {
      // margin-top:0;
      margin-right: 150px;
      margin-top: 3%;
      margin-bottom: 3%;
      min-width: 360px;
    }
    .Popular-Routes-container{
      position: relative;
    }
    .Popular-Routes-new {
      width: 270px;
      margin: 0px auto 0px;
      color: #fff;
      text-align: center;
      padding-bottom: 30px;
    }

    .search {
      display: flex;
      //max-width: 1380px;
      width: 100%;
      // min-height: 900px;
      //max-height: 900px;
      margin: auto;
      -webkit-flex-wrap: none;
      flex-wrap: none;
      padding: 0;
      position: relative;
      .join-popup {
        .modal-content {
          background: #3881ef;
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: column;
          flex-direction: column;
          -webkit-align-items: center;
          align-items: center;
          color: #fff;
          width: 590px;
          height: 680px;
          background: #3881EF;
          button.yellow-btn {
            font-size: 20px;
            font-weight: 600;
          }
        }
      }
    }

    .left-section1 {
      margin-top: 3%;
      margin-left: 10%;
      max-height: 200px;
      position: relative;
      z-index: 2;
      color: white;
      font-size: 21px;
      margin-right: 10%;
    }

    .videoTag1 {
      position: absolute;
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }
}
