@import "src/styles/variables";

.book-app {
  position: static;
  max-width: 1440px;
  width: 100%;
  height: 800px;
  left: 0px;
  top: 2181px;
  background: #000000;
  flex: none;
  order: 5;
  flex-grow: 0;
  margin: auto;

  h1 {
    position: relative;
    width: 450px;
    height: 160px;
    left: 5%;
    top: 190px;
    font-style: normal;
    font-weight: bold;
    font-size: 65px;
    line-height: 65px;
    display: flex;
    align-items: center;
    color: $grey1;
  }

  h5 {
    position: relative;
    height: 26px;
    left: 5%;
    top: 225px;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    color: $grey1;
  }

  .app-img {
    position: relative;
    left: 47%;
    top: -30%;
  }

  div {
    position: relative;
    left: -42%;
    top: 40%;

    img {
      padding-right: 10px;
    }
  }
}

.iphone-img {
  margin-top: 1rem;
  position: relative;
  left: 50%;
  top: -250px;
  width: 53%;
}

@media screen and ($mobile-media) {
  .iphone-img {
    width: 88% !important;
    position: relative;
    left: 10px;
    top: -94vw;
    margin: auto;
    margin-top: 10px;
  }

  .book-app {
    h1 {
      width: 100%;
      font-size: 13vw;
      left: 0;
      top: 45%;
      text-align: center;
      padding: 0 9%;
      line-height: 100%;
    }

    h5 {
      left: 0;
      font-size: clamp(19px, 5vw, 30px);
      text-align: center;
      width: 80%;
      margin: auto;
      line-height: 100%;
      top: 45%;
    }

    div {
      left: 0;
      top: 50%;
      display: grid;
      .app-img {
        left: 0;
        top: 0;
      }
      a {
        margin: auto;
      }
    }
  }

  .app-img {
    padding: 10px 0px;
    margin: auto;
  }
}

@media screen and ($tablet-media-min) and ($tablet-media) {
  .iphone-img {
    left: 50%;
    width: 55% !important;
  }

  .book-app {
    height: 500px;

    h1 {
      width: 48%;
      left: 5%;
      top: 100px;
      font-size: 7vw;
      line-height: 10vw;
    }

    h5 {
      position: relative;
      left: 5%;
      width: 60%;
      top: 13.5vw;
      // margin-top:1px;
      font-size: 3vw;
    }

    div {
      position: relative;
      left: 0%;
      top: 20%;
      display: flex;
      flex-direction: column;
      padding: 5px;
      width: 25%;
      height: auto;
      margin-top: 5%;
    }
    .app-img {
      padding: 5px 0px;
    }
  }
}
