@import 'src/styles/variables';

.service-block {
  width: 30%;
  padding: 0% 5%;

  img {
    padding-top: 50px;
    padding-bottom: 30px;
  }

  h4 {
    color: $grey1;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 31px;
    padding-bottom: 15px;
  }

  p {
    color: #9ca2a9;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
  }
}

@media screen and ($mobile-media) {
  .service-block {
    width: 90%;
    padding: 5%;
  }
}

@media screen and ($tablet-media-min) and ($tablet-media) {
  .service-block {
    padding: 0 5px;
  }
}
