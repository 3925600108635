@import 'src/styles/variables';

.sub-content {
  width: 40%;
  height: 151px;
  text-align: center;
  margin: 10% auto;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 46px;
  color: $grey1;
}

@media screen and ($mobile-media) {
  .sub-content {
    width: 75%;
    height: 225px;
  }
}
