@import 'src/styles/variables';

.MyTrips {
  > .body-wrapper{
    margin:auto;
    display: flex;

  > .body {
    min-height: 400px;
    display: flex;
    max-width: $page-width;
    width:95%;
    margin: auto;
    padding-top: 50px;
    padding-bottom: 50px;
    flex-wrap: wrap;

    > .MyTripsCard {
      width: 30%;
      margin: 10px 1.5%;
    }
  }
}

  >.ApplicationTab > .tab-container {
  width: 70%;
}

}

.Empty{
  margin: 0 auto;
  display:flex;
  flex-direction: column;
  img{
    max-height:94px;
    height:50%;
    margin: 0 auto;
  }
  h3{
    position: relative;
    top:10px;
    text-align: center;
    color: #848B94;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 19px;
  }
}

.MyTripsHeader {
  background-color: $black;
  color: $white;
  padding-top: 65px;
  padding-bottom: 43px;

  > h1 {
    max-width: $page-width;
    width:95%;
    padding: 20px;
    margin: auto;
    font-size: 60px;
    font-weight: 600;
    line-height: 10px;
  }
}

@media screen and ($tablet-media-min) and ($tablet-media) {
  .MyTripsHeader > h1 {
    width: 95%;
    padding: 20px;
  }

  .MyTrips > .body-wrapper > .body {
    width: 95%;
    justify-content: flex-start;
    .MyTripsCard {
      width: 45%;
      margin: 10px 1.5%;
    }
  }
}

@media screen and ($mobile-media) {
  .MyTripsHeader {
    > h1 {
      width: 100%;
      padding: 20px;
    }
  }

  .MyTrips {

    >.ApplicationTab > .tab-container {
      width: 100%;
    }

    .body-wrapper {
      .body {
        width: 100%;

        .MyTripsCard {
          width: 100%;
          margin: 10px;
        }
      }
    }
  }
}