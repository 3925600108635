@import "src/styles/variables";

.CostSummary {
  .header {
    background-color: $blue;
    color: $white;
    padding: 20px;
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;

    .total-container {
      display: flex;
      padding-bottom: 5px;
      .title {
        margin-bottom: 0;
        font-size: 20px;
      }

      .value {
        margin-bottom: 0;
        // font-size: 20px;
        text-align: end;
        align-self: center;
        margin-left: auto;
        // line-height: 140%!important;
        // font-weight: 600!important;
      }
    }

    .sub-title{
      font-size: 13.5px;
      margin-bottom: 0;
      white-space:nowrap;
      svg{
        vertical-align: middle;
      }
    }
    .body-font-p {
      margin-bottom: 0;
      white-space:nowrap;
      svg{
        vertical-align: middle;
      }
    }
    
  }

  .header-disabled{
    background-color: $grey3;
  }

  > .reward-panel {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 20px;
    background-color: $grey1;
    color: $black;
    border-radius: 24px;
    display: flex;

    > .checkbox-panel {
      margin-right: 10px;
    }

    > .info-panel {
      > .title {
        font-weight: 600;
        font-size: 18px;
        line-height: 23px;
        margin-bottom: 4px;
      }

      > .sub-title {
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        color: $blue;
        margin-bottom: 0;
      }
    }
  }

  .body {
    padding: 0 20px 20px;
    background-color: $grey1;
    color: $black;
    border-bottom-right-radius: 25px;
    border-bottom-left-radius: 25px;

    .detail-price-guaranteed {
      display: flex;
      font-size: 16px;
      padding-top: 20px;
      padding-bottom: 20px;
      border-top: 1px solid $grey2;
      font-weight: 500;
      margin-top: 24px;

      svg{
        vertical-align: text-bottom;
      }
      &:nth-child(3) {
        border-bottom: 0;
      }
      .title {
        margin-bottom: 0;
      }
    }

    .flight-concierge {
      display: flex;
      font-size: 16px;
      padding-top: 20px;
      padding-bottom: 20px;
      // border-top: 1px solid $grey2;
      font-weight: 500;
      margin-top: 20px;

      svg{
        vertical-align: text-bottom;
      }
      &:nth-child(3) {
        border-bottom: 0;
      }
      .title {
        margin-bottom: 0;
      }
    }

    .detail-container {
      display: flex;
      font-size: 16px;
      padding-top: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid $grey2;
      font-weight: 500;

      svg{
        vertical-align: text-bottom;
      }

      .sub-title {
        font-size: 13px;
        margin-bottom: 0;
      }

      &:nth-child(3) {
        border-bottom: 0;
      }

      .title {
        margin-bottom: 0;
      }

      .value {
        align-self: center;
        margin-left: auto;
        margin-bottom: 0;
      }
    }

    .detail-container-total {
      display: flex;
      font-size: 16px;
      padding-top: 20px;
      padding-bottom: 20px;
      // border-bottom: 1px solid $grey2;
      font-weight: 500;

      svg{
        vertical-align: text-bottom;
      }

      .sub-title {
        font-size: 13px;
        margin-bottom: 0;
      }

      &:nth-child(3) {
        border-bottom: 0;
      }

      .title {
        margin-bottom: 0;
      }

      .value {
        align-self: center;
        margin-left: auto;
        margin-bottom: 0;
      }
    }



    .TripPriceContainer{
      flex-direction: column;
      padding: 16px 0;
      div{
        margin-bottom: 4px;
      }
    }
  }

  .subtitile-h5, .subtitile-h4 {
    margin-bottom: 0px;
  }
}

.info-text-recommended {
  color: $grey3;
  width: 85%;
  text-align: center;
  margin: auto;
  padding-top: 30px;
  padding-bottom: 0px!important;
}

.fuel-msg {
  margin-right: 5px;
  vertical-align: middle!important;
}

.fuel-msg-div {
  // display: inline-flex;
  margin-top: 10px;
  text-align: center;
  align-items: center;
}