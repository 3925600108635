@import "src/styles/variables";

.ConciergeModal {
  .ConciergeModalContainer {
    display: block !important;
    // width: 85%;
    .title {
      font-weight: 600;
      font-size: 25px !important;
      line-height: 38px;
      font-style: normal;
    }
    .sub-title {
      text-align: left;
      margin-top: 25px;
      font-style: normal;
      font-weight: 500;
      font-size: 17px;
      line-height: 19px;
      letter-spacing: -.01em;
      margin-bottom: 15px;
    }
  }
  .ConciergeListContainer {
    display: flex !important;
    border-top: 1px solid $grey2;
  }
  .itemLeft {
    padding-top: 25px;
    padding-bottom: 15px;
    width: 15%;
    font-weight: bold;
    font-size: 17px;
  }
  .itemRight {
    font-family: $font-regular;
    padding-top: 28px;
    padding-bottom: 28px;
    width: 80%;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #000;
  }
}
.full-width-container {
  padding-top: 6px;
  padding-bottom: 6px;
  border-radius: 5px 5px;
  border: 1px solid #aeaeae;
  width: 100%;
  &:focus {
    outline: none;
  }
}
.delete-msg {
   margin-top:5px;
}
.disable-btn {
  opacity: .5;
}
.Modal .modal-content{
  top: 15%!important;
}
