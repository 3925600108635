@import "src/styles/variables";

.login-root {
  background-color: #000;
  padding: 20px 20px 70px 20px;
  h1 {
    font-size: 48px !important;
    font-weight: 600;
    // line-height: 52.8px;
  }

  .title {
    width: 70%;
    margin: auto;
    text-align: left;
    color: $white;
    font-size: 48px;
    font-weight: 600;
    margin-top: -10px;
  }
}

.phone{
  padding-left: 25%;
}

.newUser{
  color: #9CA2A9;
  margin-left: 74px!important;
  margin-left: -10px;
}

.login-form {
  width: 70%;
  margin: auto;
  padding: 40px 0;
  .black-input{
    width: 100%;
  }
  // .InputBox{
  //   .input-container{
  //     .input{
  //       background-color: #000;
  //       color: #fff;
  //       border-bottom: 1px solid #2c3034;
  //       margin: 0!important;
  //       padding: 0!important;
  //       //margin: 8px 0px 0px;
  //       //padding: 10px 0px;
  //       padding-bottom: 15px!important;
  //     }
  //   }
  // }
  .field-value{
    padding-bottom: 15px;
    padding: 0;
    margin: 0;
    padding: 0px!important;
    padding-bottom: 15px!important;
    margin: 0!important;
    margin-top: 3px;
  }
}

.confirm-mail {
  .close {
    color: #000 !important;
    top: 5px !important;
    right: 5px !important;
  }
}

.social-login-icon-links {
  padding-top: 20px;

  .social-login-container {
    display: flex;
    width: 70%;
    margin: auto;
    color: $white;
    justify-content: center;
    margin-top: -20px;
    // width: 100px;
    button {
      margin-top: 5px;
      // margin-right: 10px;
      width: 100px;
    }
  }

  p {
    text-align: center;
    color: $white;
  }

  span {
    text-align: center;
    color: #fff;
    font-size: 17px;
  }
}

.cust-no {
  display: flex;

  .tel-number {
    width: 30%;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.message-ca {
  max-width: 467.79px;
  border-radius: 18px;
  padding: 9px 19px;
  background: #3881ef;
  font-size: 15px;
  font-weight: 400;
  color: #fff;
  margin-top: 25px;
  margin-left: 8px;
}

.danger {
  margin-bottom: 0;
  text-align: left;
  color: #ef3838;
  font-size: 14px;
  margin-top: -3px;
}


.error {
  border-bottom: 1px solid #ef3838 !important; /* Red border for input fields with errors */
  margin: 0;
}

@media screen and ($mobile-media) {
  .login-form {
    width: 50%;
  }
  .login-root {
    .title {
      width: 50%;
    }
  }
}

@media screen and ($tablet-media-min) and ($tablet-media) {
  .login-form {
    width: 70%;
  }
  .login-root {
    .title {
      width: 70%;
    }
  }
  .message-ca {
    max-width: 467.79px;
    margin-left: 20px;
  }
}
